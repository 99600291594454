import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '36px'
  },
  '& .MuiSelect-select': {
    height: '36px !important',
    padding: '0 32px 0 14px !important',
    display: 'flex',
    alignItems: 'center',
    minHeight: 'unset !important',
    lineHeight: 'unset !important'
  }
}));

function BookSelector({ value, onChange, books = [] }) {
  return (
    <StyledFormControl fullWidth>
      <Select
        value={value || ''}
        onChange={onChange}
        displayEmpty
      >
        <MenuItem value="" disabled>Select a Book</MenuItem>
        {books.map((book) => (
          <MenuItem key={book.book_id} value={book.book_id}>
            {book.title}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default BookSelector;