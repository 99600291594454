import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Card, CardMedia, CardActionArea, DialogActions, Button, CircularProgress, Alert } from '@mui/material';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';

const GallerySelectModal = ({ open, onClose, onAssign }) => {
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGalleryImages = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data, error } = await supabase
        .from('gallery')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });
      if (error) throw error;
      // For each image, create a signed URL for preview.
      const imagesWithUrl = await Promise.all(data.map(async (img) => {
        const { data: urlData, error: urlError } = await supabase.storage
          .from('images')
          .createSignedUrl(img.image_path, 3600); // 1 hour validity
        if (urlError) {
          console.error('Signed URL error for', img.image_path, urlError);
          return { ...img, url: null };
        }
        return { ...img, url: urlData.signedUrl };
      }));
      setImages(imagesWithUrl);
    } catch (err) {
      console.error('Error fetching gallery images:', err);
      setError(err.message || JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchGalleryImages();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Choose an Image from Gallery</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Grid container spacing={2}>
            {images.map((img) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={img.id}>
                <Card>
                  <CardActionArea onClick={() => { onAssign(img.image_path); onClose(); }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={img.url || 'https://via.placeholder.com/150?text=No+Image'}
                      alt="Gallery Image"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GallerySelectModal;