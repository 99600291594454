import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, TextField, Typography, Button, Avatar, Box, Grid, Alert } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import supabase from '../../api/supabase';

const UserProfile = () => {
  const { user, updateProfile } = useAuth();
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    authorBio: '',
    avatar_url: ''
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      setProfileData({
        name: user.user_metadata?.name || '',
        email: user.email || '',
        address: user.user_metadata?.address || '',
        phone: user.user_metadata?.phone || '',
        authorBio: user.user_metadata?.authorBio || '',
        avatar_url: user.user_metadata?.avatar_url || ''
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      await updateProfile({
        name: profileData.name,
        address: profileData.address,
        phone: profileData.phone,
        authorBio: profileData.authorBio,
        avatar_url: profileData.avatar_url
      });
      setSuccessMessage('Profile updated successfully!');
    } catch (err) {
      setError(err.message || 'Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            User Profile
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <Avatar src={profileData.avatar_url} sx={{ width: 80, height: 80 }} />
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth label="Name" name="name" value={profileData.name} onChange={handleChange} required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Email" name="email" value={profileData.email} disabled helperText="Email cannot be changed" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Address" name="address" value={profileData.address} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Phone" name="phone" value={profileData.phone} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth multiline rows={4} label="Author Bio" name="authorBio" value={profileData.authorBio} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Avatar URL" name="avatar_url" value={profileData.avatar_url} onChange={handleChange} helperText="Provide a URL for your avatar image" />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth disabled={loading}>
                  {loading ? 'Saving...' : 'Save Profile'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UserProfile