import React, { useState } from 'react';
import { Box, List, Typography, Button, Divider, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../common/Logo';
import { useAuth } from '../../hooks/useAuth';
import { useUI } from '../../hooks/useUI';  // Provides toggleThemeCustomizer and themeCustomizerOpen
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// Import drawer components:
import UserDetails from '../common/UserDetails';
import AIAssistantDrawer from '../tools/AIAssistantDrawer';
import ThemeCustomizerDrawer from '../theme/ThemeCustomizerDrawer';

const SidebarContainer = styled(Box)({
  width: 'var(--sidebar-width)',
  backgroundColor: 'var(--primary-light)',
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1000
});

const SidebarLogo = styled(Box)({
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  borderBottom: '0px solid rgba(0,0,0,0.1)',
  marginTop: 10,
  marginRight: 1,
  marginLeft: 18
});

const SidebarNav = styled(Box)({
  flex: 1,
  padding: '24px 16px',
  overflowY: 'hidden',
  marginBottom: '12px',
  display: 'flex',
  flexDirection: 'column'
});

const NavGroup = styled(Box)({
  marginBottom: '24px'
});

const NavGroupTitle = styled(Typography)({
  color: 'var(--text-secondary)',
  fontSize: '1rem',
  letterSpacing: '0.1em',
  fontFamily: "'Quicksand', Sans-serif",
  padding: '0 12px',
  marginBottom: '6px'
});

const AiAssistantButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '100%',
  marginBottom: 0,
  padding: theme.spacing(1),
  padding: '10px',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: 'white',
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontSize: '0.8rem',
  fontWeight: 800,
  transition: 'all 0.3s ease',
  animation: 'pulse 2s infinite',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${theme.palette.primary.main}40`
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(168, 53, 98, 0.4)'
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(168, 53, 98, 0)'
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(168, 53, 98, 0)'
    }
  }
}));

const NavItem = styled('a')(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  margin: '1px 0',
  borderRadius: '8px',
  color: active ? 'white' : 'var(--text-primary)',
  textDecoration: 'none',
  transition: 'all 0.2s ease',
  gap: '12px',
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '1rem',
  fontWeight: 500,
  backgroundColor: active ? 'var(--primary-color)' : 'transparent',
  '&:hover': {
    backgroundColor: active ? 'var(--primary-color)' : 'rgba(168, 54, 99, 0.1)'
  },
  '& .material-icons': {
    fontSize: 24
  }
}));

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  
  // From UI context, we have the theme customizer toggle and state.
  const { toggleThemeCustomizer, themeCustomizerOpen } = useUI();

  // Local state for our AI assistant drawer:
  const [assistantOpen, setAssistantOpen] = useState(false);

  const handleNavClick = (path, e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <SidebarContainer>
      <SidebarLogo>
        <Logo />
      </SidebarLogo>
      <SidebarNav>
        <NavGroup>
          <NavGroupTitle>Main</NavGroupTitle>
          {/* Ensure /dashboard route exists in App.js */}
          <NavItem 
            href="/dashboard" 
            active={location.pathname === '/dashboard'}
            onClick={(e) => handleNavClick('/dashboard', e)}
          >
            <i className="material-icons">dashboard</i>
            <span>Dashboard</span>
          </NavItem>
        </NavGroup>
        <NavGroup>
          <NavGroupTitle>Customize myPages</NavGroupTitle>
          <NavItem 
            href="/profile" 
            active={location.pathname === '/profile'}
            onClick={(e) => handleNavClick('/profile', e)}
          >
            <i className="material-icons">person</i>
            <span>Profile & Pen-name</span>
          </NavItem>
          <NavItem
            onClick={(e) => { 
              e.preventDefault();
              toggleThemeCustomizer();
            }}
            style={{ cursor: 'pointer' }}
          >
            <i className="material-icons">palette</i>
            <span>Theme & Layout</span>
          </NavItem>
          <NavItem 
            href="/settings/integrations" 
            active={location.pathname === '/settings/integrations'}
            onClick={(e) => handleNavClick('/settings/integrations', e)}
          >
            <i className="material-icons">extension</i>
            <span>Integrations</span>
          </NavItem>
        </NavGroup>
        <NavGroup>
          <NavGroupTitle>Generative AI</NavGroupTitle>
          <NavItem 
            href="/tools/calibrator" 
            active={location.pathname === '/tools/calibrator'}
            onClick={(e) => handleNavClick('/tools/calibrator', e)}
          >
            <i className="material-icons">tune</i>
            <span>Calibrator</span>
          </NavItem>
          <NavItem 
            href="/settings/model" 
            active={location.pathname === '/settings/model'}
            onClick={(e) => handleNavClick('/settings/model', e)}
          >
            <i className="material-icons">psychology</i>
            <span>Gen AI settings</span>
          </NavItem>
        </NavGroup>
        <NavGroup>
          <NavGroupTitle>Tools</NavGroupTitle>
          <NavItem 
            href="/tools/character-chat" 
            active={location.pathname === '/tools/character-chat'}
            onClick={(e) => handleNavClick('/tools/character-chat', e)}
          >
            <i className="material-icons">chat</i>
            <span>Character Chat</span>
          </NavItem>
          <NavItem 
            href="/tools/scene-builder" 
            active={location.pathname === '/tools/scene-builder'}
            onClick={(e) => handleNavClick('/tools/scene-builder', e)}
          >
            <i className="material-icons">auto_stories</i>
            <span>Scene Builder</span>
          </NavItem>
          <NavItem 
            href="/tools/imagemaker" 
            active={location.pathname === '/tools/imagemaker'}
            onClick={(e) => handleNavClick('/tools/imagemaker', e)}
          >
            <i className="material-icons">image</i>
            <span>Gallery & Image Generator</span>
          </NavItem>
        </NavGroup>
        <Divider />
        <Box sx={{ mt: 'auto', ml: 0, mr: 1.5 }}>
          <AiAssistantButton
            startIcon={<ArrowForwardIcon />}
            onClick={() => setAssistantOpen(true)}
          >
            Launch AI Assistant
          </AiAssistantButton>
        </Box>
      </SidebarNav>
      
      {/* Render drawers outside of the visible Nav */}
      <AIAssistantDrawer open={assistantOpen} onClose={() => setAssistantOpen(false)} />
      <ThemeCustomizerDrawer 
        open={themeCustomizerOpen} 
        onClose={toggleThemeCustomizer} 
        themeSettings={{}}  // Pass your current theme settings as needed
        onUpdateTheme={(newSettings) => { 
          // Here you could update the theme; for now, simply log it
          console.log('Theme updated:', newSettings);
        }}
      />
    </SidebarContainer>
  );
}

export default Sidebar