import React from 'react';
import { Box, styled } from '@mui/material';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

const LayoutRoot = styled(Box)({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#fafafa'
});

const MainContent = styled(Box)({
    flexGrow: 1,
    minHeight: '100vh',
    marginTop: 'calc(var(--topbar-height) + 0px)', // Added padding
    marginLeft: 'var(--sidebar-width)',
    backgroundColor: 'var(--background-default)',
    padding: '12px'
  });

function MainLayout({ children }) {
  return (
    <LayoutRoot>
      <Sidebar />
      <TopBar />
      <MainContent>
        {children}
      </MainContent>
    </LayoutRoot>
  );
}

export default MainLayout;