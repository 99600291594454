import React, { useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Avatar,
  Typography,
  Divider,
  ListItemIcon
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontSize: '0.9rem',
  fontFamily: "'Secular One', sans-serif",
  fontWeight: 500
}));

const MenuTitle = styled(Typography)({
  fontFamily: "'Secular One', sans-serif",
  fontSize: '1.1rem',
  color: 'var(--text-primary)'
});

const UserNameText = styled(Typography)({
  fontSize: '0.9rem',
  color: 'var(--text-primary)',
  fontFamily: "'Quicksand', sans-serif",
  fontWeight: 600
});

const UserEmailText = styled(Typography)({
  fontSize: '0.8rem',
  color: 'var(--text-secondary)',
  fontFamily: "'Quicksand', sans-serif"
});

const MenuHeader = styled(Box)({
  padding: '16px',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
});

const StyledMenuItem = styled(MenuItem)({
  padding: '12px 16px',
  fontSize: '0.91rem',
  fontFamily: "'Quicksand', sans-serif",
  fontWeight: 500,
  gap: '12px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)'
  }
});
const formatName = (name) => {
  if (!name) return '';
  return name.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1.5,
          cursor: 'pointer',
          padding: '4px 8px',
          borderRadius: '20px',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.04)'
          }
        }}
        onClick={handleClick}
      >
        <UserAvatar>
          {user?.user_metadata?.name?.split(' ')
            .map(n => n[0])
            .slice(0, 2)
            .join('')
            .toUpperCase() || 
           user?.email?.split('@')[0]
            .slice(0, 2)
            .toUpperCase() || 
           'US'}
        </UserAvatar>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <UserNameText>
            {formatName(user?.user_metadata?.name) || user?.email?.split('@')[0]}
          </UserNameText>
        </Box>
      </Box>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 280,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            borderRadius: '12px',
            mt: 1.5
          },
        }}
      >
        <MenuHeader>
          <UserAvatar sx={{ width: 40, height: 40, fontSize: '1.1rem' }}>
            {user?.user_metadata?.name?.split(' ')
              .map(n => n[0])
              .slice(0, 2)
              .join('')
              .toUpperCase() || 
             user?.email?.split('@')[0]
              .slice(0, 2)
              .toUpperCase() || 
             'US'}
          </UserAvatar>
          <Box>
            <UserNameText>
              {formatName(user?.user_metadata?.name) || 'User'}
            </UserNameText>
            <UserEmailText>
              {user?.email}
            </UserEmailText>
          </Box>
        </MenuHeader>
        
        <StyledMenuItem onClick={() => { handleClose(); navigate('/profile'); }}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </StyledMenuItem>
        <StyledMenuItem onClick={() => { handleClose(); navigate('/settings'); }}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </StyledMenuItem>
        <StyledMenuItem onClick={() => { handleClose(); navigate('/billing'); }}>
          <ListItemIcon>
            <PaymentIcon fontSize="small" />
          </ListItemIcon>
          Billing
        </StyledMenuItem>
        <Divider sx={{ my: 1 }} />
        <StyledMenuItem 
          onClick={signOut} 
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          Sign Out
        </StyledMenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;