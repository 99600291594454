import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAvatar = styled(MuiAvatar)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
  fontWeight: 600,
  border: '2px solid #fff'
}));

function Avatar({ user, size, ...props }) {
  const getInitials = (user) => {
    if (!user) return 'U';
    if (user.name) {
      return user.name
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
    }
    if (user.email) {
      return user.email[0].toUpperCase();
    }
    return 'U';
  };

  return (
    <StyledAvatar
      {...props}
      sx={{
        ...(size && {
          width: size,
          height: size,
          fontSize: size * 0.4
        }),
        ...props.sx
      }}
    >
      {user?.avatar_url ? (
        <img src={user.avatar_url} alt={user.name || 'User avatar'} />
      ) : (
        getInitials(user)
      )}
    </StyledAvatar>
  );
}

export default Avatar