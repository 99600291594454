import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from './Avatar';

const UserDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 8px',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const UserInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px'
});

const UserName = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.9rem',
  lineHeight: 1.2
});

const UserRole = styled(Typography)({
  fontSize: '0.8rem',
  lineHeight: 1.2,
  color: 'text.secondary'
});

function UserDetails({ user, onClick }) {
  return (
    <UserDetailsContainer onClick={onClick}>
      <Avatar 
        user={user}
        size={32}
      />
      <UserInfo>
        <UserName>
          {user?.name || user?.email?.split('@')[0] || 'User'}
        </UserName>
        <UserRole>
          {user?.role || 'Member'}
        </UserRole>
      </UserInfo>
    </UserDetailsContainer>
  );
}

export default UserDetails;