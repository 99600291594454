import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';  // Import global styles
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { UIProvider } from './context/UIContext'; // ensure this is imported
import { ThemeProvider as CustomThemeProvider } from './context/ThemeContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <UIProvider>
        <CustomThemeProvider>
          <App />
        </CustomThemeProvider>
      </UIProvider>
    </AuthProvider>
  </React.StrictMode>
);