import React, { useState } from 'react';
import {
  IconButton,
  Badge,
  Popover,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';

const NotificationListItem = styled(ListItem)(({ theme, unread }) => ({
  backgroundColor: unread ? theme.palette.primary.light : 'transparent',
  padding: '16px',
  borderBottom: '1px solid rgba(0,0,0,0.05)',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  transition: 'background-color 0.2s ease',
}));

const NotificationIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: '40px'
}));

function NotificationsDropdown({ notifications = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const unreadCount = notifications.filter(n => !n.read).length;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllRead = () => {
    // Implement mark all as read functionality
  };

  const getNotificationIcon = (type) => {
    switch(type) {
      case 'ai':
        return <AutoAwesomeIcon />;
      case 'chat':
        return <PsychologyIcon />;
      case 'image':
        return <ImageIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  return (
    <>
      <IconButton 
        onClick={handleClick}
        sx={{ 
          color: 'inherit',
          position: 'relative'
        }}
      >
        <Badge 
          badgeContent={unreadCount} 
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              right: -3,
              top: 3,
              border: '2px solid #fff',
              padding: '0 4px',
            }
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: 320,
            maxHeight: 480,
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
            borderRadius: '12px'
          }
        }}
      >
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          bgcolor: 'background.paper'
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Notifications
          </Typography>
          <Button 
            onClick={handleMarkAllRead}
            size="small"
            sx={{ 
              textTransform: 'none',
              fontSize: '0.875rem'
            }}
          >
            Mark all as read
          </Button>
        </Box>
        
        <List sx={{ 
          maxHeight: 400, 
          overflow: 'auto',
          p: 0,
          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '3px'
          }
        }}>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationListItem 
                key={notification.id} 
                unread={!notification.read}
                sx={{ 
                  cursor: 'pointer',
                  transition: 'all 0.2s ease'
                }}
              >
                <NotificationIcon>
                  {getNotificationIcon(notification.type)}
                </NotificationIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" sx={{ fontWeight: !notification.read ? 600 : 400 }}>
                      {notification.title}
                    </Typography>
                  }
                  secondary={
                    <Box sx={{ mt: 0.5 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        {notification.message}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {notification.time}
                      </Typography>
                    </Box>
                  }
                />
              </NotificationListItem>
            ))
          ) : (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography color="text.secondary">
                No notifications
              </Typography>
            </Box>
          )}
        </List>
      </Popover>
    </>
  );
}

export default NotificationsDropdown;