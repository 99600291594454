import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, CircularProgress, Alert } from '@mui/material';
import supabase from '../../api/supabase';
import SceneCard from './SceneCard';
import BasicSceneForm from './BasicSceneForm';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const ScenesList = ({ bookId }) => {
  const { user } = useAuth();
  const [scenes, setScenes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const navigate = useNavigate();

  const fetchScenes = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('scenes')
        .select('*')
        .eq('book_id', bookId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      setScenes(data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bookId) {
      fetchScenes();
    }
  }, [bookId]);

  const handleSceneAdded = (newScene) => {
    setScenes(prev => [newScene, ...prev]);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Scenes
      </Typography>
      <Button
        variant="contained"
        onClick={() => setAddDialogOpen(true)}
        sx={{ mb: 2 }}
      >
        + Add Scene
      </Button>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : scenes.length === 0 ? (
        <Typography>No scenes available. Create one!</Typography>
      ) : (
        <Grid container spacing={2}>
          {scenes.map(scene => (
            <Grid item key={scene.scene_id} xs={12} sm={6} md={4} lg={3}>
              <SceneCard scene={scene} />
            </Grid>
          ))}
        </Grid>
      )}
      <BasicSceneForm
        bookId={bookId}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSceneAdded={handleSceneAdded}
      />
    </Box>
  );
};

export default ScenesList;