// src/theme.js
import { createTheme } from '@mui/material/styles';

export const createCustomTheme = (settings = {}) => {
  return createTheme({
    palette: {
      primary: {
        main: 'rgb(168, 54, 99)',
        light: 'rgba(168, 54, 99, 0.03)',
      },
      text: {
        primary: '#333333',
        secondary: '#666666',
      },
    },
    typography: {
      fontFamily: "'Quicksand', sans-serif",
      h1: {
        fontFamily: "'Secular One', sans-serif",
      },
      h2: {
        fontFamily: "'Secular One', sans-serif",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          :root {
            --primary-color: rgb(168, 54, 99);
            --primary-light: rgba(168, 54, 99, 0.03);
            --text-primary: #333333;
            --text-secondary: #666666;
            --sidebar-width: 240px;
            --topbar-height: 64px;
          }
        `
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: 'var(--primary-light)',
            width: 'var(--sidebar-width)',
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            left: 'var(--sidebar-width)',
            width: 'calc(100% - var(--sidebar-width))',
          }
        }
      }
    }
  });
};

export default createCustomTheme;