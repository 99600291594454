import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Stack
} from "@mui/material";
import { Close as CloseIcon, Send as SendIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../hooks/useAuth";
import supabase from "../../api/supabase";

const AIAssistantDrawer = ({ open, onClose }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]); // Each message: { role: "user" or "assistant", text: "..." }
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Create a new chat, if needed.
  const handleNew = () => {
    const newChatId = `${Date.now()}-${Math.random().toString(36).substr(2, 5)}`;
    setChatId(newChatId);
    setMessages([]);
    return newChatId;
  };

  // Placeholder implementations for load and clear
  const handleLoad = () => {
    console.log("Load chat with chatId:", chatId);
    // Implement retrieval from your database if desired.
  };

  const handleClear = () => {
    setMessages([]);
    // Optionally update your DB for the current chat.
  };

  const handleSend = async () => {
    if (!input.trim()) return;
    // Append user message to our local messages array.
    const userMsg = { role: "user", text: input };
    const currentHistory = messages.map((msg) => ({
      role: msg.role,
      content: msg.text
    }));
    // Ensure a chatId exists.
    const currentChatId = chatId || handleNew();
  
    // Optimistically add the user message.
    setMessages((prev) => [...prev, userMsg]);
    const requestBody = {
      user_id: user?.id,
      chat_id: currentChatId,
      message: input,
      history: currentHistory
    };
    setInput("");
    setLoading(true);
    setError(null);
    try {
      // Call your deployed edge function endpoint using a normal fetch.
      const { data, error: fnError } = await supabase.functions.invoke("assistant-chat", {
        body: {
          prompt: input,
          history: messages
        }
      });
      if (fnError) {
        throw new Error(fnError.message);
      }
      
      // data is already parsed JSON, so use data.message directly.
      const reply = data?.message || "No response";
      const aiMsg = { role: "assistant", text: reply };
      setMessages(prev => [...prev, aiMsg]);
    } catch (err) {
      console.error("AI Assistant error:", err);
      setError(err.message || "Failed to get AI response");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      // Override drawer styling so it appears in bottom-right.
      PaperProps={{
        sx: {
          position: "fixed",
          bottom: 0,
          right: 0,
          left: "auto",
          width: 350,
          height: 750,
          backgroundColor: theme.palette.background.paper,
          m: 0,
        },
      }}
      // Optional: adjust backdrop opacity if too dark.
      ModalProps={{ 
        BackdropProps: {
          sx: { backgroundColor: "rgba(0,0,0,0.3)" }
        }
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/* Header */}
        <Box sx={{ p: 2, borderBottom: "1px solid #ddd", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h6">myPages Assistant</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        {/* Chat control buttons */}
        <Stack direction="row" spacing={1} sx={{ p: 2 }}>
          <Button variant="outlined" size="small" onClick={handleNew}>New</Button>
          <Button variant="outlined" size="small" onClick={handleLoad}>Load</Button>
          <Button variant="outlined" size="small" onClick={handleClear}>Clear</Button>
        </Stack>
        
        {/* Conversation */}
        <Box sx={{ flexGrow: 1, p: 2, overflowY: "auto" }}>
          {messages.length === 0 && (
            <Typography variant="body2" color="textSecondary">
              Start a conversation by clicking "New" then type a message.
            </Typography>
          )}
          <List>
            {messages.map((msg, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemText
                  primary={msg.role === "user" ? "You" : "Assistant"}
                  secondary={msg.text}
                />
              </ListItem>
            ))}
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
          </List>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
        </Box>
        
        {/* Input area */}
        <Box sx={{ p: 2, borderTop: "1px solid #ddd" }}>
          <TextField
            fullWidth
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            multiline
            rows={2}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 1 }}
            onClick={handleSend}
            disabled={loading || !input.trim()}
            startIcon={<SendIcon />}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AIAssistantDrawer;
