import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Stack,
  Divider,
  Alert
} from '@mui/material';
import ImageUploader from '../common/ImageUploader';
import AIImageGeneratorModal from '../tools/AIImageGeneratorModal';
import GallerySelectModal from '../tools/GallerySelectModal';
import { useAuth } from '../../hooks/useAuth';
import supabase from '../../api/supabase';
import getSignedUrl from '../../utils/getSignedUrl';

const LocationImagePickerDialog = ({ open, onClose, onSelect, initialImage, bookId }) => {
  const { user } = useAuth();
  const [chosenImagePath, setChosenImagePath] = useState(initialImage || '');
  const [previewUrl, setPreviewUrl] = useState('');
  const [openAIModal, setOpenAIModal] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [error, setError] = useState('');

  // Update the preview URL using getSignedUrl whenever the chosenImagePath changes.
  useEffect(() => {
    if (chosenImagePath) {
      getSignedUrl('images', chosenImagePath, 60 * 60 * 24).then(url => {
        setPreviewUrl(url);
      });
    } else {
      setPreviewUrl('');
    }
  }, [chosenImagePath]);

  const handleImageSelected = (filePath) => {
    setChosenImagePath(filePath);
  };

  const handleClear = () => {
    setChosenImagePath('');
    setPreviewUrl('');
  };

  const handleSave = () => {
    if (!chosenImagePath) {
      setError('Please select an image or cancel.');
      return;
    }
    onSelect(chosenImagePath);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Location Image</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Stack spacing={2}>
          {previewUrl ? (
            <Box sx={{ textAlign: 'center' }}>
              <img
                src={previewUrl}
                alt="Selected"
                style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
              />
              <Button onClick={handleClear} variant="text" color="secondary">
                Clear Selection
              </Button>
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary" align="center">
              No image selected yet.
            </Typography>
          )}
          <Divider />
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="outlined" onClick={() => setOpenAIModal(true)}>
              Generate with AI
            </Button>
            <Button variant="outlined" onClick={() => setOpenGalleryModal(true)}>
              Choose from Gallery
            </Button>
          </Stack>
          <Typography variant="body2" color="text.secondary" align="center">
            Or upload your own image below:
          </Typography>
          <Box sx={{ mt: 1 }}>
            <ImageUploader
              onUpload={handleImageSelected}
              initialImage={chosenImagePath}
              bucket="images"
              path={`${user.id}/${bookId}/locations`}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
      <AIImageGeneratorModal
        open={openAIModal}
        onClose={() => setOpenAIModal(false)}
        onAssign={(filePath) => {
          handleImageSelected(filePath);
          setOpenAIModal(false);
        }}
      />
      <GallerySelectModal
        open={openGalleryModal}
        onClose={() => setOpenGalleryModal(false)}
        onAssign={(filePath) => {
          handleImageSelected(filePath);
          setOpenGalleryModal(false);
        }}
      />
    </Dialog>
  );
};

export default LocationImagePickerDialog;