import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import { createCustomTheme } from './theme';
import { useAuth } from './hooks/useAuth';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Dashboard from './components/dashboard/Dashboard';
import { Box, CircularProgress } from '@mui/material';
import ImageGenerator from './components/tools/ImageGenerator';
import Gallery from './components/tools/Gallery';
import Calibrator from './components/tools/Calibrator';
import CharacterChat from './components/tools/CharacterChat/CharacterChat';
import SceneBuilder from './components/tools/SceneBuilder/SceneBuilder';
import UserProfile from './components/auth/UserProfile';


function App() {
  const theme = createCustomTheme();
  const { user, loading } = useAuth();
  

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  // Public routes (login, register)
  if (!user) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }

  // Protected routes (main app)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <MainLayout>
          <Routes>
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route path="/profile" element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            } />
            <Route 
              path="/tools/imagemaker" 
              element={
                <ProtectedRoute>
                  <ImageGenerator />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/gallery" 
              element={
                <ProtectedRoute>
                  <Gallery />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/calibrator" 
              element={
                <ProtectedRoute>
                  <Calibrator />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/character-chat" 
              element={
                <ProtectedRoute>
                  <CharacterChat />
                </ProtectedRoute>
              } 
            />
                        <Route 
              path="/tools/character-chat/:bookId" 
              element={
                <ProtectedRoute>
                  <CharacterChat />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/character-chat/:bookId/:characterId" 
              element={
                <ProtectedRoute>
                  <CharacterChat />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/scene-builder" 
              element={
                <ProtectedRoute>
                  <SceneBuilder />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/tools/scene-builder/:bookId" 
              element={
                <ProtectedRoute>
                  <SceneBuilder />
                </ProtectedRoute>
              } 
            />

            {/* Add other protected rou,mktes here */}
          </Routes>
        </MainLayout>
      </Router>
    </ThemeProvider>
  );
}

export default App;