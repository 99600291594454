import React, { useState, useEffect } from 'react';
import { Box, Container, Tabs, Tab } from '@mui/material';
import WelcomePanel from './WelcomePanel';
import BookDetailsLayout from './BookDetailsLayout';
import CharacterSection from './characters/CharacterSection';
import PlotPointsPanel from '../../components/tools/plotPoints/PlotPointsPanel';
import SettingPanel from './SettingsPanel';
import StylePOVSettingsTable from '../settings/StylePOVSettingsTable';
import LocationSettingsTable from '../settings/LocationSettingsTable';
import ScenesList from '../scene/ScenesList';
import { useBooks } from '../../hooks/useBooks';

function Dashboard() {
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const { books, loading, error, addBook } = useBooks();

  useEffect(() => {
    if (books.length > 0 && selectedBookId === null) {
      setSelectedBookId(books[0].book_id);
    }
  }, [books, selectedBookId]);

  const handleBookSelect = (bookId) => {
    setSelectedBookId(bookId);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 3 }}>
        <WelcomePanel
          books={books}
          onBookSelect={handleBookSelect}
          onCreateBook={addBook}
          loading={loading}
          error={error}
        />
        {selectedBookId && (
          <>
            <BookDetailsLayout bookId={selectedBookId} />
            <CharacterSection bookId={selectedBookId} />
            <PlotPointsPanel bookId={selectedBookId} />
            <LocationSettingsTable bookId={selectedBookId} />
            <Box sx={{ mt: 4 }}>
              <Tabs value={tabIndex} onChange={handleTabChange} centered>
                <Tab label="Style / POV Settings" />
                <Tab label="Scenes Overview" />
              </Tabs>
              {tabIndex === 0 && <StylePOVSettingsTable bookId={selectedBookId} />}
              {tabIndex === 1 && <ScenesList bookId={selectedBookId} />}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}

export default Dashboard