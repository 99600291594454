import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Tabs,
  Tab,
  Grid,
  MenuItem,
  Divider,
  CircularProgress,
  styled
} from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import supabase from '../../../services/supabase';

const TabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0)
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTab-root': {
    textTransform: 'none',
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 600,
    fontSize: '0.95rem',
    minHeight: 48,
  }
}));

const GENRES = [
  'Romance', 'Mystery', 'Science Fiction', 'Fantasy', 'Thriller',
  'Historical Fiction', 'Literary Fiction', 'Horror', 'Young Adult',
  'Children\'s', 'Biography', 'Memoir', 'Self-Help', 'Crime',
  'Adventure', 'Contemporary Fiction', 'Dystopian', 'Paranormal',
  'Women\'s Fiction', 'Literary Nonfiction'
];

const POV_OPTIONS = [
  'First Person',
  'Second Person',
  'Third Person Limited',
  'Third Person Omniscient',
  'Multiple POV'
];

const NARRATOR_TYPES = [
  'Character Narrator',
  'Omniscient Narrator',
  'Limited Omniscient',
  'Objective/Dramatic',
  'Stream of Consciousness',
  'Unreliable Narrator'
];

function BookDetailsPanel({ bookId }) {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    // Basic Details
    title: '',
    description: '',
    genre: '',
    target_audience: '',
    // Plot
    plot_overview: '',
    conflict: '',
    resolution: '',
    // Theme
    theme: '',
    morals: '',
    symbolism: '',
    // Writing Style
    setting: '',
    pov: '',
    narrator_type: '',
    // Author & Dedication
    pen_name: '',
    author_bio: '',
    dedication_to: '',
    dedication_text: ''
  });

  useEffect(() => {
    if (bookId) loadBookDetails();
  }, [bookId]);

  const loadBookDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('books')
        .select('*')
        .eq('book_id', bookId)  // Changed from 'id' to 'book_id'
        .single();

      if (error) throw error;
      if (data) {
        setFormData({
          title: data.title || '',
          description: data.description || '',
          genre: data.genre || '',
          target_audience: data.target_audience || '',
          plot_overview: data.plot_overview || '',
          conflict: data.conflict || '',
          resolution: data.resolution || '',
          theme: data.theme || '',
          morals: data.morals || '',
          symbolism: data.symbolism || '',
          setting: data.setting || '',
          pov: data.pov || '',
          narrator_type: data.narrator_type || '',
          pen_name: data.pen_name || '',
          author_bio: data.author_bio || '',
          dedication_to: data.dedication_to || '',
          dedication_text: data.dedication_text || ''
        });
      }
    } catch (err) {
      console.error('Error loading book details:', err);
      setError('Failed to load book details');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title.trim()) {
      setError('Title is required');
      return;
    }

    try {
      setSaving(true);
      const { error } = await supabase
        .from('books')
        .upsert({
          book_id: bookId,  // Changed from 'id' to 'book_id'
          user_id: user.id,
          ...formData,
          updated_at: new Date()
        });

      if (error) throw error;
      setSuccess('Details saved successfully!');
    } catch (err) {
      console.error('Error saving details:', err);
      setError('Failed to save details');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flex: 1 }}>
        <StyledTabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Basic Details" />
          <Tab label="Plot" />
          <Tab label="Theme" />
          <Tab label="Writing Style" />
          <Tab label="Author & Dedication" />
        </StyledTabs>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {/* Basic Details Tab */}
          <TabPanel role="tabpanel" hidden={activeTab !== 0}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Genre"
                  name="genre"
                  value={formData.genre}
                  onChange={handleChange}
                >
                  {GENRES.map((genre) => (
                    <MenuItem key={genre} value={genre}>
                      {genre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Target Audience"
                  name="target_audience"
                  value={formData.target_audience}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* Plot Tab */}
          <TabPanel role="tabpanel" hidden={activeTab !== 1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Plot Overview"
                  name="plot_overview"
                  value={formData.plot_overview}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Conflict"
                  name="conflict"
                  value={formData.conflict}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Resolution (Climax)"
                  name="resolution"
                  value={formData.resolution}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* Theme Tab */}
          <TabPanel role="tabpanel" hidden={activeTab !== 2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Theme"
                  name="theme"
                  value={formData.theme}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Morals / Learnings (Optional)"
                  name="morals"
                  value={formData.morals}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Symbolism"
                  name="symbolism"
                  value={formData.symbolism}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* Writing Style Tab */}
          <TabPanel role="tabpanel" hidden={activeTab !== 3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Setting"
                  name="setting"
                  value={formData.setting}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Point of View"
                  name="pov"
                  value={formData.pov}
                  onChange={handleChange}
                >
                  {POV_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Narrator Type"
                  name="narrator_type"
                  value={formData.narrator_type}
                  onChange={handleChange}
                >
                  {NARRATOR_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Author & Dedication Tab */}
          <TabPanel role="tabpanel" hidden={activeTab !== 4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pen Name"
                  name="pen_name"
                  value={formData.pen_name}
                  onChange={handleChange}
                  helperText="Leave blank to use your actual name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Author Biography"
                  name="author_bio"
                  value={formData.author_bio}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Dedicated To"
                  name="dedication_to"
                  value={formData.dedication_to}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Dedication Text"
                  name="dedication_text"
                  value={formData.dedication_text}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Similar Works / Authors"
                  name="similar_references"
                  value={formData.similar_references}
                  onChange={(e) => setFormData(prev => ({ ...prev, similar_references: e.target.value }))}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </Grid>
          </TabPanel>

          <Divider sx={{ mt: 3, mb: 2 }} />
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={loadBookDetails}
              disabled={saving}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save All Details'}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

export default BookDetailsPanel;