import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import ImageUploader from '../../common/ImageUploader';

function ChangeAvatarModal({ open, onClose, character, onUpdate }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // This function is called by the ImageUploader when a new file is successfully uploaded
  const handleImageUpload = async (filePath) => {
    if (!filePath) {
      // If user removed the avatar, you might set image_path to null or keep the old one
      return;
    }
    try {
      setLoading(true);
      setError(null);

      // onUpdate is typically a function from parent that updates the DB record for this character
      await onUpdate({ image_path: filePath });
      onClose();
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Failed to upload image: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!character) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Change Character Avatar</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box sx={{ my: 2 }}>
          <ImageUploader
            onUpload={handleImageUpload}
            initialImage={character.image_path}
            bucket="images"
            maxSize={5000000} // 5 MB
          />
        </Box>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeAvatarModal