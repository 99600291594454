import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert,
  CircularProgress,
  Box,
  Typography
} from '@mui/material';
import ImageUploader from '../common/ImageUploader';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';

const BasicSceneForm = ({ bookId, open, onClose, onSceneAdded }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    stylePOV: '',
    characters: '',
    additionalDetails: ''
  });
  const [imagePath, setImagePath] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Convert additional details text (one line per sentence) into JSON (keys: "1", "2", ...).
  const parseAdditionalDetails = (text) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const detailsObj = {};
    lines.forEach((line, index) => {
      detailsObj[String(index + 1)] = line.trim();
    });
    return detailsObj;
  };

  const handleSubmit = async () => {
    if (!formData.title.trim() || !formData.description.trim()) {
      setError('Title and Description are required.');
      return;
    }
    setSubmitting(true);
    setError('');
    const payload = {
      book_id: bookId,
      title: formData.title.trim(),
      description: formData.description.trim(),
      style_pov: formData.stylePOV.trim(),
      characters: formData.characters
        ? formData.characters.split(',').map(s => s.trim())
        : [],
      settings: {
        image_path: imagePath || null,
        additional_details: parseAdditionalDetails(formData.additionalDetails)
      },
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
    try {
      const { data, error } = await supabase
        .from('scenes')
        .insert([payload])
        .select()
        .single();
      if (error) throw error;
      onSceneAdded(data);
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Scene</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Scene Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Scene Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Style / POV (optional)"
            name="stylePOV"
            value={formData.stylePOV}
            onChange={handleChange}
            placeholder="e.g. Narration by internal monologue, casual dialogue"
          />
          <TextField
            fullWidth
            label="Characters (comma separated, optional)"
            name="characters"
            value={formData.characters}
            onChange={handleChange}
            placeholder="e.g. Alice, Bob"
          />
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Additional Details (optional)"
            name="additionalDetails"
            value={formData.additionalDetails}
            onChange={handleChange}
            placeholder="Enter one detail per line (e.g. 'beach in warm climate')"
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Location Image (optional)
            </Typography>
            <ImageUploader
              onUpload={setImagePath}
              initialImage={imagePath}
              bucket="images"
              path={`${user.id}/${bookId}/scenes`}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={submitting}>
          {submitting ? <CircularProgress size={24} /> : 'Save Scene'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BasicSceneForm;