import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Menu,
  MenuItem,
  styled,
  Typography,
  ListItemIcon
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationsDropdown from '../common/NotificationsDropdown';
import UserMenu from '../common/UserMenu';
import { useNavigate } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SecurityIcon from '@mui/icons-material/Security';
import GavelIcon from '@mui/icons-material/Gavel';

const MenuHeader = styled(Box)({
  padding: '16px',
  borderBottom: '1px solid rgba(0,0,0,0.1)'
});

const MenuTitle = styled(Typography)({
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '1.1rem',
  color: 'rgb(168, 54, 99)'
});

const StyledMenuItem = styled(MenuItem)({
  padding: '12px 16px',
  fontSize: '0.9rem',
  fontFamily: "'Quicksand', sans-serif",
  fontWeight: 500,
  gap: '12px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)'
  }
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0,0,0,0.12)',
  height: 'calc((var(--topbar-height)))',
  width: `calc(100% - var(--sidebar-width))`,
  left: 'var(--sidebar-width)',
  position: 'fixed',
  zIndex: 900,
  '& .MuiToolbar-root': {
    height: '100%',
    minHeight: 'var(--topbar-height)',
    padding: '0 24px'
  }
}));

const NavGroup = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  marginLeft: 'auto' // Push everything to the right
});

const TopBarButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: 'none',
  fontSize: '0.9rem',
  fontFamily: "'Quicksand', sans-serif",
  fontWeight: 500,
  padding: '6px 12px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)'
  }
}));

const ActionLink = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'none',
  fontSize: '0.9rem',
  fontWeight: 500,
}));

const TopBar = () => {
  const navigate = useNavigate();
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const [privacyAnchorEl, setPrivacyAnchorEl] = useState(null);
  const [bookManagerEl, setBookManagerEl] = useState(null);

  console.log('privacyAnchorEl:', privacyAnchorEl);
  console.log('bookManagerEl:', bookManagerEl);
  console.log('helpAnchorEl:', helpAnchorEl);

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <NavGroup>
          <TopBarButton
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => {
              console.log('Setting bookManagerEl');
              setBookManagerEl(e.currentTarget);
            }}
          >
            Book & File Manager
          </TopBarButton>
          <Menu
            anchorEl={bookManagerEl}
            open={Boolean(bookManagerEl)}
            onClose={() => setBookManagerEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 280,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                borderRadius: '12px',
                mt: 1.5
              },
            }}
          >
            <MenuHeader>
              <MenuTitle>Book & File Manager</MenuTitle>
            </MenuHeader>
            <StyledMenuItem onClick={() => {
              setBookManagerEl(null);
              navigate('/book/export');
            }}>
              <ListItemIcon>
                <HelpOutlineIcon fontSize="small" />
              </ListItemIcon>
              Export book
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setBookManagerEl(null);
              navigate('/book/import');
            }}>
              <ListItemIcon>
                <QuestionAnswerIcon fontSize="small" />
              </ListItemIcon>
              Import book
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setBookManagerEl(null);
              navigate('/book/branch');
            }}>
              <ListItemIcon>
                <ContactSupportIcon fontSize="small" />
              </ListItemIcon>
              Document & File viewer
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setBookManagerEl(null);
              navigate('/book/document');
            }}>
              <ListItemIcon>
                <ContactSupportIcon fontSize="small" />
              </ListItemIcon>
              Document viewer
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setBookManagerEl(null);
              navigate('/book/gallery');
            }}>
              <ListItemIcon>
                <ContactSupportIcon fontSize="small" />
              </ListItemIcon>
              Gallery
            </StyledMenuItem>
          </Menu>

          <TopBarButton
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => setHelpAnchorEl(e.currentTarget)}
          >
            Help & Support
          </TopBarButton>
          <Menu
            anchorEl={helpAnchorEl}
            open={Boolean(helpAnchorEl)}
            onClose={() => setHelpAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 280,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                borderRadius: '12px',
                mt: 1.5
              },
            }}
          >
            <MenuHeader>
              <MenuTitle>Help & Support</MenuTitle>
            </MenuHeader>
            <StyledMenuItem onClick={() => {
              setHelpAnchorEl(null);
              navigate('/help/articles');
            }}>
              <ListItemIcon>
                <HelpOutlineIcon fontSize="small" />
              </ListItemIcon>
              Help Articles
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setHelpAnchorEl(null);
              navigate('/help/faqs');
            }}>
              <ListItemIcon>
                <QuestionAnswerIcon fontSize="small" />
              </ListItemIcon>
              FAQs
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setHelpAnchorEl(null);
              navigate('/help/contact');
            }}>
              <ListItemIcon>
                <ContactSupportIcon fontSize="small" />
              </ListItemIcon>
              Get in Touch
            </StyledMenuItem>
          </Menu>

          <TopBarButton
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => setPrivacyAnchorEl(e.currentTarget)}
          >
            Privacy & Safety
          </TopBarButton>
          <Menu
            anchorEl={privacyAnchorEl}
            open={Boolean(privacyAnchorEl)}
            onClose={() => setPrivacyAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 280,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                borderRadius: '12px',
                mt: 1.5
              },
            }}
          >
            <MenuHeader>
              <MenuTitle>Privacy & Safety</MenuTitle>
            </MenuHeader>
            <StyledMenuItem onClick={() => {
              setPrivacyAnchorEl(null);
              navigate('/privacy-policy');
            }}>
              <ListItemIcon>
                <GavelIcon fontSize="small" />
              </ListItemIcon>
              Privacy Policy
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setPrivacyAnchorEl(null);
              navigate('/privacy-settings');
            }}>
              <ListItemIcon>
                <SecurityIcon fontSize="small" />
              </ListItemIcon>
              Privacy & Safety Settings
            </StyledMenuItem>
          </Menu>
          <NotificationsDropdown />
          <UserMenu />
        </NavGroup>
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopBar;