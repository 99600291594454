// src/context/ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';
import supabase from '../api/supabase';
import { useAuth } from '../hooks/useAuth';

export const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [themeSettings, setThemeSettings] = useState({});
  const { user } = useAuth();
  

  useEffect(() => {
    if (user) {
      loadUserTheme();
    }
  }, [user]);

  const loadUserTheme = async () => {
    const { data, error } = await supabase
      .from('user_themes')
      .select('*')
      .eq('user_id', user.id)
      .eq('is_active', true)
      .single();

    if (!error && data) {
      setThemeSettings(data.settings);
    }
  };

  const updateTheme = async (newSettings) => {
    try {
      const { error } = await supabase
        .from('user_themes')
        .update({ settings: newSettings })
        .eq('user_id', user.id)
        .eq('is_active', true);

      if (!error) {
        setThemeSettings(newSettings);
      }
    } catch (error) {
      console.error('Error updating theme:', error);
    }
  };

  return (
    <ThemeContext.Provider value={{ themeSettings, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}