import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ImageGenerator from './ImageGenerator';

const AIImageGeneratorModal = ({ open, onClose, onAssign }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Generate Image with AI</DialogTitle>
      <DialogContent>
        <ImageGenerator 
          onAssign={(result) => {
            // Pass the filePath, not the URL
            onAssign(result.filePath);
            onClose();
          }} 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AIImageGeneratorModal;