import React, { useState } from 'react';
import { Box, Typography, Alert, styled } from '@mui/material';
import CharacterCarousel from './CharacterCarousel';
import EditCharacterModal from './EditCharacterModal';
import ChangeAvatarModal from './ChangeAvatarModal';
import { useBook } from '../../../hooks/useBook';
import { useNavigate } from 'react-router-dom';

const SectionContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4)
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Secular One', sans-serif",
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3)
}));

function CharacterSection({ bookId }) {
  const navigate = useNavigate();
  const { book, createCharacter, updateCharacter } = useBook(bookId);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [error, setError] = useState(null);

  const handleSaveCharacter = async (characterData) => {
    try {
      if (selectedCharacter) {
        await updateCharacter(selectedCharacter.character_id, characterData);
      } else {
        await createCharacter(characterData);
      }
      setEditModalOpen(false);
    } catch (err) {
      setError('Failed to save character');
      console.error('Error:', err);
    }
  };

  const handleAvatarUpdate = async (characterId, updates) => {
    try {
      await updateCharacter(characterId, updates);
      setAvatarModalOpen(false);
    } catch (err) {
      setError('Failed to update avatar');
      console.error('Error:', err);
    }
  };

  return (
    <SectionContainer>
      <SectionTitle variant="h5">
        Characters
      </SectionTitle>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <CharacterCarousel
        characters={book?.characters || []}
        onCreateCharacter={() => {
          setSelectedCharacter(null);
          setEditModalOpen(true);
        }}
        onEditCharacter={(character) => {
          setSelectedCharacter(character);
          setEditModalOpen(true);
        }}
        onImageUpload={(character) => {
          setSelectedCharacter(character);
          setAvatarModalOpen(true);
        }}
        onChatWithCharacter={(character) => {
          navigate(`/tools/character-chat/${bookId}/${character.character_id}`);
        }}
      />

      <EditCharacterModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        character={selectedCharacter}
        onSave={handleSaveCharacter}
      />

      <ChangeAvatarModal
        open={avatarModalOpen}
        onClose={() => setAvatarModalOpen(false)}
        character={selectedCharacter}
        onUpdate={(updates) => 
          handleAvatarUpdate(selectedCharacter.character_id, updates)
        }
      />
    </SectionContainer>
  );
}

export default CharacterSection;