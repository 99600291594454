import React from 'react';
import { 
  Box, 
  Typography,
  Button,
  useTheme,
  styled 
} from '@mui/material';
import BookSelector from '../common/BookSelector';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import welcomeBg from '../../assets/patterns/welcome-bg.svg';

const WelcomeContainer = styled(Box)(({ theme }) => ({
  background: 'white',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  position: 'relative', // Added to help with absolute positioning
  backgroundImage: `url(${welcomeBg})`,
}));

const SelectorBox = styled(Box)(({ theme }) => ({
  background: 'transparent',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  position: 'relative',
  marginLeft: '-220px', // Move towards center
  '& > *:first-of-type': {  // Target BookSelector
    width: '200px',         // Fixed width for BookSelector
    flexShrink: 0          // Prevent shrinking
  },
  '& > button': {          // Target CreateBookButton
    whiteSpace: 'nowrap',  // Prevent text wrapping
    flexShrink: 0          // Prevent shrinking
  }
}));

const CreateBookButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  maxHeight: '36px',
  textTransform: 'none',
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  minWidth: 'fit-content', // Added to prevent squashing
  '&:hover': {
    background: theme.palette.primary.dark
  }
}));
const ArrowIcon = styled('svg')(({ theme }) => ({
  width: '100px',
  height: '60px',
  margin: '0 20px',
  transform: 'translateY(20px) rotate(-30deg)',
  position: 'absolute',
  left: '-220px', // Adjusted position
  top: '10px',
  '& path': {
    fill: 'none',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeLinecap: 'round'
  }
}));


const GreetingText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Playfair Display',
  fontSize: '2rem',
  maxWidth: '400px',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2)
}));

const formatName = (name) => {
  if (!name) return '';
  return name.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const HandwrittenText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Mali', cursive",
  fontSize: '1.2rem',
  color: theme.palette.text.primary,
  marginTop: '40px'
}));







function WelcomePanel({ books, onBookSelect, loading, error }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const firstName = formatName(user?.user_metadata?.name?.split(' ')[0] || 'Author');

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  };

  return (
    <WelcomeContainer>
      <Box>
        <GreetingText>
          {getGreeting()}, {firstName}
        </GreetingText>
        <HandwrittenText>
          Simply choose or create a book to get started..
        </HandwrittenText>
      </Box>

      <SelectorBox>
        <BookSelector 
          value={books.length > 0 ? books[0].book_id : ''}
          onChange={(e) => onBookSelect(e.target.value)}
          books={books}
        />
        <CreateBookButton
          startIcon={<AddIcon />}
          onClick={() => navigate('/books/create')}
        >
          Create New Book
        </CreateBookButton>
        <ArrowIcon viewBox="0 0 120 60">
          <path d="M0,30 Q30,10 60,30 T120,30 M90,10 L120,30 L90,50" />
        </ArrowIcon>
      </SelectorBox>
    </WelcomeContainer>
  );
}

export default WelcomePanel;