
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Alert,
  CircularProgress
} from '@mui/material';

function EditCharacterModal({ open, onClose, character, onSave }) {
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    traits: [],
    background: '',
    appearance: '',
    common_phrases: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (character) {
      setFormData({
        name: character.name || '',
        role: character.role || '',
        traits: Array.isArray(character.personality_traits) ? character.personality_traits : [],
        background: character.background_story || '',
        appearance: character.appearance || '',
        common_phrases: Array.isArray(character.common_phrases) ? character.common_phrases : []
      });
    }
  }, [character]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!formData.name.trim()) {
      setError('Name is required');
      return;
    }
    try {
      setLoading(true);
      setError(null);

      const updates = {
        name: formData.name,
        role: formData.role,
        personality_traits: `{${formData.traits.join(',')}}`,
        background_story: formData.background,
        appearance: formData.appearance,
        common_phrases: `{${formData.common_phrases.join(',')}}`
      };

      await onSave(updates);
      onClose();
    } catch (err) {
      console.error('Error saving character:', err);
      setError('Failed to save character');
    } finally {
      setLoading(false);
    }
  };

  const arrayToString = (arr) => (arr || []).join(', ');
  const stringToArray = (str) =>
    str.split(',')
      .map(s => s.trim())
      .filter(Boolean);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {character ? 'Edit Character' : 'Create New Character'}
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Character Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Role (Protagonist, NPC, etc.)"
              name="role"
              value={formData.role}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Appearance"
              name="appearance"
              value={formData.appearance}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Common Phrases (comma separated)"
              name="common_phrases"
              value={arrayToString(formData.common_phrases)}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                common_phrases: stringToArray(e.target.value)
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Background / Story"
              name="background"
              value={formData.background}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Personality Traits (comma separated)"
              name="traits"
              value={arrayToString(formData.traits)}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                traits: stringToArray(e.target.value)
              }))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCharacterModal