import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Stack,
  Chip,
  styled,
  useTheme
} from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import supabase from '../../api/supabase';

const StyledSceneCard = styled(Card)(({ theme }) => ({
  width: 320,
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)'
  }
}));

const SceneCard = ({ scene }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  // If the scene contains a location image within its settings,
  // get the public URL from storage.
  const locationImageUrl =
    scene.settings && scene.settings.image_path
      ? supabase.storage.getPublicUrl('images', scene.settings.image_path).data.publicUrl
      : null;

  return (
    <StyledSceneCard>
      {locationImageUrl && (
        <CardMedia
          component="img"
          height="180"
          image={locationImageUrl}
          alt="Scene location"
        />
      )}
      <CardContent sx={{ p: 2 }}>
        <Stack spacing={1}>
          <Typography variant="h6">
            {scene.title || 'Untitled Scene'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {scene.description ? scene.description.slice(0, 80) + '…' : 'No description provided.'}
          </Typography>
          {scene.style_pov && (
            <Chip label={scene.style_pov} size="small" color="primary" sx={{ width: '100%' }} />
          )}
          {scene.characters && scene.characters.length > 0 && (
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
              {scene.characters.map((char, index) => (
                <Chip key={index} label={char} size="small" variant="outlined" />
              ))}
            </Box>
          )}
        </Stack>
      </CardContent>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          justifyContent: 'center',
          borderTop: '1px solid rgba(0,0,0,0.1)'
        }}
      >
        <Button
          size="small"
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() =>
            navigate(`/tools/scene-builder/${scene.book_id}/${scene.scene_id}`)
          }
        >
          View
        </Button>
      </Box>
    </StyledSceneCard>
  );
};

export default SceneCard;