import React from 'react';
import { Card, Box, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const StyledCard = styled(Card)(({ theme }) => ({
  width: 220,  // Match CharacterCard width
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: 'transparent',
  boxShadow: 'none',
  cursor: 'pointer',
  padding: theme.spacing(3),
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main
    }
  }
}));

const addIcon = styled(AddIcon)(({ theme }) => ({
  fontSize: 40,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

const CreateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: "'Quicksand', sans-serif",
  fontWeight: 500,
  textAlign: 'center'
}));

function CreateCharacterCard({ onClick }) {
  return (
    <StyledCard onClick={onClick}>
      <addIcon />
      <CreateText>
        Create New Character
      </CreateText>
    </StyledCard>
  );
}

export default CreateCharacterCard;