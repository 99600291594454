import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Alert,
  CircularProgress,
  Dialog,
  styled,
  useTheme
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { useAuth } from '../../../hooks/useAuth';
import supabase from '../../../api/supabase';
import PlotPointDialog from './PlotPointDialog';

const TimelineContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#fff',
  borderRadius: 8,
  padding: '40px',
  marginBottom: '20px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  border: '1px solid rgba(0,0,0,0.1)',
  minHeight: 500,
  overflow: 'hidden'
}));

const Timeline = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 400,
  overflowX: 'auto',
  overflowY: 'hidden',
  padding: '0 100px', // Add padding for better visibility of edge items
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#555',
    },
  },
}));

const TimelineLine = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '50%',
  height: 3,
  background: '#e0e0e0',
  zIndex: 1,
  minWidth: '100%', // Ensure line extends full width
});


const TimelineEvent = styled(Box)(({ left }) => ({
  position: 'absolute',
  left: `${left}%`,
  top: '50%', // This ensures it's centered on the timeline
  transform: 'translateX(-50%)', // Only transform X axis
  zIndex: 2,
}));

const EventMarker = styled('div')(({ type, theme }) => ({
  width: 20,
  height: 20,
  borderRadius: type === 'key_moment' ? 0 : '50%',
  background: type === 'key_moment' ? theme.palette.secondary.main : 
              type === 'character_development' ? theme.palette.primary.main :
              theme.palette.info.main,
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the marker
  zIndex: 3,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translate(-50%, -50%) scale(1.2)',
    boxShadow: '0 0 15px rgba(168, 54, 99, 0.4)',
  },
}));

const EventConnector = styled('div')(({ height, position }) => ({
  position: 'absolute',
  width: 2,
  background: '#e0e0e0',
  left: '50%',
  height,
  transform: 'translateX(-50%)',
  ...(position === 'above' ? {
    bottom: '10px', // Adjust this value to control spacing
  } : {
    top: '10px', // Adjust this value to control spacing
  })
}));

const EventContent = styled('div')(({ position }) => ({
  position: 'absolute',
  width: 220,
  background: '#fff',
  padding: 15,
  borderRadius: 8,
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  border: '1px solid rgba(0,0,0,0.1)',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 4,
  ...(position === 'above' ? { 
    bottom: '30px',
  } : { 
    top: '30px',
  }),
  '& .plot-point-title': {
    fontFamily: 'Secular One',
    color: '#a83663',
    fontSize: '1rem',
    marginBottom: '4px'
  },
    '& .plot-point-description': {
      fontFamily: 'Secular One',
      color: '#666',
      fontSize: '0.875rem'
    }
  }));

function PlotPointsPanel({ bookId }) {
  const theme = useTheme();
  const { user } = useAuth();
  const [plotPoints, setPlotPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (bookId) {
      fetchPlotPoints();
    }
  }, [bookId]);

  const fetchPlotPoints = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('plot_points')
        .select('*')
        .eq('book_id', bookId)
        .order('position');

      if (error) throw error;
      setPlotPoints(data || []);
    } catch (err) {
      setError('Failed to load plot points');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (formData) => {
    try {
      const plotPoint = {
        ...formData,
        book_id: bookId,
        position: selectedPoint?.position || plotPoints.length + 1
      };

      if (selectedPoint?.plot_point_id) {
        const { error } = await supabase
          .from('plot_points')
          .update(plotPoint)
          .eq('plot_point_id', selectedPoint.plot_point_id);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('plot_points')
          .insert([plotPoint]);
        if (error) throw error;
      }

      await fetchPlotPoints();
      setDialogOpen(false);
      setSelectedPoint(null);
    } catch (err) {
      setError('Failed to save plot point');
      console.error(err);
    }
  };

  const handleDelete = async (plotPointId) => {
    try {
      const { error } = await supabase
        .from('plot_points')
        .delete()
        .eq('plot_point_id', plotPointId);

      if (error) throw error;
      await fetchPlotPoints();
    } catch (err) {
      setError('Failed to delete plot point');
      console.error(err);
    }
  };

  const calculatePosition = (index, total) => {
    // Distribute points evenly across the timeline
    return (index + 1) * (100 / (total + 1));
  };

  return (
    <TimelineContainer>
      <Typography variant="h6" sx={{ marginBottom: '30px', fontFamily: 'Secular One' }}>
        Plot Points Timeline
      </Typography>

      {/* Legend */}
      <Box sx={{ position: 'absolute', top: 20, left: 20, display: 'flex', gap: 20, fontSize: '0.85rem', color: '#666' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Box sx={{ width: 12, height: 12, borderRadius: '50%', background: theme.palette.primary.main }}></Box>
          Character Development
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Box sx={{ width: 12, height: 12, clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)', background: theme.palette.secondary.main }}></Box>
          Key Moment
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Box sx={{ width: 12, height: 12, borderRadius: '50%', background: theme.palette.info.main }}></Box>
          Background Context
        </Box>
      </Box>

      {/* Controls */}
      <Box sx={{ position: 'absolute', top: 20, right: 20, display: 'flex', gap: 10, zIndex: 10 }}>
        <Button 
          variant="contained" 
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setDialogOpen(true)}
        >
          Add Plot Point
        </Button>
      </Box>

      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Timeline>
          <TimelineLine />
          {plotPoints.map((point, idx) => (
            <TimelineEvent 
              key={point.plot_point_id} 
              left={calculatePosition(idx, plotPoints.length)}
            >
              <EventConnector 
                height={80} 
                position={idx % 2 === 0 ? 'above' : 'below'} 
              />
              <EventMarker 
                type={point.type}
                onClick={() => {
                  setSelectedPoint(point);
                  setDialogOpen(true);
                }}
              />
                <EventContent position={idx % 2 === 0 ? 'above' : 'below'}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography className="plot-point-title">
                      {point.title}
                    </Typography>
                    <Box>
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          setSelectedPoint(point);
                          setDialogOpen(true);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton 
                        size="small"
                        onClick={() => handleDelete(point.plot_point_id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography className="plot-point-description">
                    {point.description}
                  </Typography>
                </EventContent>
            </TimelineEvent>
          ))}
        </Timeline>
      )}

      <PlotPointDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelectedPoint(null);
        }}
        plotPoint={selectedPoint}
        onSave={handleSave}
        bookId={bookId}
      />
    </TimelineContainer>
  );
}

export default PlotPointsPanel;