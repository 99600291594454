import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Alert,
  Card,
  CardMedia,
  Stack,
  Divider
} from '@mui/material';
import {
  Person as PersonIcon,
  Book as BookIcon,
  PhotoLibrary as GalleryIcon,
  LocationOn as LocationIcon
} from '@mui/icons-material';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';
import getSignedUrl from '../../utils/getSignedUrl';

/**
 * Types of assignments:
 * "character" → updating characters.image_path
 * "cover" → updating books.cover_image
 * "setting" → updating settings.image_path
 */

const ASSIGNMENT_TYPES = [
  {
    value: 'character',
    label: 'Character Avatar',
    icon: PersonIcon,
    table: 'characters',
    imageColumn: 'image_path',
    idField: 'character_id',
    titleColumn: 'name'
  },
  {
    value: 'cover',
    label: 'Book Cover',
    icon: BookIcon,
    table: 'books',
    imageColumn: 'cover_image',
    idField: 'book_id',
    titleColumn: 'title'
  },
  {
    value: 'setting',
    label: 'Setting Image',
    icon: LocationIcon,
    table: 'settings',
    imageColumn: 'image_path',
    idField: 'setting_id',
    titleColumn: 'name'
  }
];

function AssignImageDialog({ open, onClose, imageUrl, onSave }) {
  const { user } = useAuth();
  const [assignmentType, setAssignmentType] = useState('');
  const [assignmentTarget, setAssignmentTarget] = useState('');
  const [availableTargets, setAvailableTargets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (!open) {
      setAssignmentType('');
      setAssignmentTarget('');
      setError(null);
      setSuccess(null);
      setAvailableTargets([]);
    }
  }, [open]);

  useEffect(() => {
    let isMounted = true;
    if (imageUrl) {
      getSignedUrl('images', imageUrl).then((url) => {
        if (isMounted) {
          setPreviewUrl(url);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [imageUrl]);

  useEffect(() => {
    if (!assignmentType) {
      setAvailableTargets([]);
      setAssignmentTarget('');
      return;
    }
    loadTargets();
  }, [assignmentType]);

  const loadTargets = async () => {
    try {
      setLoading(true);
      setError(null);

      const config = ASSIGNMENT_TYPES.find(t => t.value === assignmentType);
      if (!config) {
        setError(`Unknown assignment type: ${assignmentType}`);
        return;
      }

      let query;
      switch(config.table) {
        case 'books':
          query = supabase
            .from('books')
            .select('*')
            .eq('user_id', user.id);
          break;
        case 'characters':
          query = supabase
            .from('characters')
            .select(`
              character_id,
              name,
              book_id,
              books!inner(user_id)
            `)
            .eq('books.user_id', user.id);
          break;
        case 'settings':
          query = supabase
            .from('settings')
            .select('*')
            .eq('user_id', user.id);
          break;
        default:
          throw new Error(`Unknown table: ${config.table}`);
      }

      const { data, error } = await query;
      if (error) throw error;
      setAvailableTargets(data || []);
    } catch (err) {
      console.error('Error loading targets:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAssign = async () => {
    try {
      if (!imageUrl || !assignmentType || !assignmentTarget) return;
      setLoading(true);
      setError(null);
      setSuccess(null);

      const config = ASSIGNMENT_TYPES.find(t => t.value === assignmentType);

      const { error: updateError } = await supabase
        .from(config.table)
        .update({ [config.imageColumn]: imageUrl })
        .eq(config.idField, assignmentTarget);
      if (updateError) throw updateError;

      setSuccess('Image assigned successfully!');
      if (onSave) {
        onSave(imageUrl);
      }
    } catch (err) {
      console.error('Error assigning image:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const config = ASSIGNMENT_TYPES.find(t => t.value === assignmentType);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Image</DialogTitle>
      <DialogContent>
        {imageUrl && (
          <Card elevation={0} sx={{ mb: 2 }}>
            <CardMedia
              component="img"
              height="200"
              image={previewUrl}
              alt="To assign"
              sx={{
                objectFit: 'contain',
                borderRadius: 1
              }}
            />
          </Card>
        )}

        {error && (
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel>Assignment Type</InputLabel>
            <Select
              value={assignmentType}
              label="Assignment Type"
              onChange={(e) => setAssignmentType(e.target.value)}
              disabled={loading}
            >
              {ASSIGNMENT_TYPES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <type.icon />
                    <Typography>{type.label}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {assignmentType && (
            <FormControl fullWidth>
              <InputLabel>
                {config?.table === 'books' ? 'Select Book' : config?.table === 'settings' ? 'Select Setting' : 'Select Character'}
              </InputLabel>
              <Select
                value={assignmentTarget}
                label="Select Target"
                onChange={(e) => setAssignmentTarget(e.target.value)}
                disabled={loading || availableTargets.length === 0}
              >
                {availableTargets.map((target) => (
                  <MenuItem key={target[config.idField]} value={target[config.idField]}>
                    {target[config.titleColumn]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleAssign}
          disabled={loading || !assignmentType || !assignmentTarget || !imageUrl}
        >
          {loading ? 'Assigning...' : 'Assign'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignImageDialog;