import supabase from '../api/supabase';

const getSignedUrl = async (bucket, path, expiresIn = 3600) => {
  if (!path) return null;
  try {
    const { data, error } = await supabase.storage
      .from(bucket)
      .createSignedUrl(path, expiresIn);
    if (error) {
      console.error(`Error creating signed URL for ${path}:`, error);
      return null;
    }
    return data.signedUrl;
  } catch (err) {
    console.error(`Error in getSignedUrl for ${path}:`, err);
    return null;
  }
};

export default getSignedUrl