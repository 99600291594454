import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../api/supabase';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionExpiry, setSessionExpiry] = useState(null);

  useEffect(() => {
    checkUser();
    
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth event:', event);
      setUser(session?.user ?? null);
      setSessionExpiry(session?.expires_at ?? null);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const checkUser = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setSessionExpiry(session?.expires_at ?? null);
      console.log('Session check:', session ? 'Active' : 'No session');
    } catch (error) {
      console.error('Error checking user:', error);
    } finally {
      setLoading(false);
    }
  };

  const signIn = async ({ email, password }) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) throw error;
    return data.user;
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    window.location.href = '/login'; // Force redirect to login
  };

  const signUp = async ({ email, password, userData }) => {
    // userData can hold stuff like fullName, etc. that you want in user_metadata
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: userData // user_metadata
      }
    });
    if (error) throw error;
    // data.user will be the newly created user
    return data;
  };

  // Add session info to the context
  const value = {
    user,
    loading,
    signIn,
    signOut,
    signUp,
    sessionExpiry,
    isAuthenticated: !!user,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};