import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import CharacterCard from './CharacterCard';
import CreateCharacterCard from './CreateCharacterCard';

const CarouselContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.paper,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.light,
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

function CharacterCarousel({ characters = [], onCreateCharacter, onEditCharacter, onChatWithCharacter }) {
  return (
    <CarouselContainer>
      <Grid container spacing={2} wrap="nowrap">
        {characters.map((character) => (
          <Grid item key={character.character_id}>
            <CharacterCard
              character={character}
              onEdit={() => onEditCharacter(character)}
              onChat={() => onChatWithCharacter(character)}
            />
          </Grid>
        ))}
        <Grid item>
          <CreateCharacterCard onClick={onCreateCharacter} />
        </Grid>
      </Grid>
    </CarouselContainer>
  );
}

export default CharacterCarousel;