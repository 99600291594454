import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';
import AssignImageDialog from './AssignImageDialog';

const Gallery = () => {
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  useEffect(() => {
    if (user) {
      fetchImages();
    }
  }, [user]);

  const fetchImages = async () => {
    try {
      setLoading(true);
      setError(null);

      // Get all references from "gallery"
      const { data, error } = await supabase
        .from('gallery')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      if (!data) return;

      // For each image, create a signed URL
      const withUrls = await Promise.all(
        data.map(async (row) => {
          const { data: signData, error: urlErr } = await supabase.storage
            .from('images')
            .createSignedUrl(row.image_path, 60 * 60 * 24); // 24 hours
          if (urlErr) {
            console.error('Signed URL error for', row.image_path, urlErr);
          }
          return {
            ...row,
            url: signData?.signedUrl || null
          };
        })
      );

      setImages(withUrls);
    } catch (err) {
      console.error('Failed to load gallery images:', err);
      setError('Failed to load images.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (image) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }
    try {
      // Delete from storage first
      await supabase.storage
        .from('images')
        .remove([image.image_path]);

      // Now delete from the "gallery" table
      await supabase
        .from('gallery')
        .delete()
        .eq('id', image.id);

      // Remove from local state
      setImages(prev => prev.filter(i => i.id !== image.id));
    } catch (err) {
      console.error('Delete error:', err);
      setError('Failed to delete image.');
    }
  };

  const handleAssignClick = (image) => {
    setSelectedImage(image);
    setAssignDialogOpen(true);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Image Gallery
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Grid container spacing={2}>
        {images.map((img) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={img.id}>
            <Card>
              <CardMedia
                component="img"
                image={img.url || 'https://via.placeholder.com/400?text=No+Image'}
                alt={img.prompt || 'AI Image'}
                sx={{
                  aspectRatio: '1',
                  objectFit: 'cover'
                }}
              />
              <CardContent sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="text.secondary">
                  {img.type || 'Type?'}
                </Typography>
                <IconButton
                  onClick={(e) => {
                    setSelectedImage(img);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Menu for each image */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleAssignClick(selectedImage);
          }}
        >
          <AssignmentIcon sx={{ mr: 1 }} />
          Assign
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleDelete(selectedImage);
          }}
        >
          <DeleteIcon sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      {/* Dialog to assign the selected image to a character or a book cover */}
      <AssignImageDialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
        imageUrl={selectedImage?.url}
        onSave={() => {
          // If you need to refresh or do anything after assignment, do it here
        }}
      />
    </Box>
  );
};

export default Gallery