import React, { useState, useEffect } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert, CircularProgress, Stack, Typography } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';

const StylePOVSettingsTable = ({ bookId }) => {
  const { user } = useAuth();
  const [styleSettings, setStyleSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);

  // Form state for Add/Edit
  const [formData, setFormData] = useState({
    title: '',
    sampleText: '',
    references: ''
  });

  const fetchStyleSettings = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('style_pov_settings')
        .select('*')
        .eq('user_id', user.id)
        .eq('book_id', bookId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      setStyleSettings(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bookId && user) {
      fetchStyleSettings();
    }
  }, [bookId, user]);

  const handleOpenAdd = () => {
    setFormData({ title: '', sampleText: '', references: '' });
    setCurrentSetting(null);
    setDialogOpen(true);
  };

  const handleOpenEdit = (setting) => {
    setCurrentSetting(setting);
    setFormData({
      title: setting.title || '',
      sampleText: setting.sample_text || '',
      references: setting.references || ''
    });
    setDialogOpen(true);
  };

  const handleDelete = async (settingId) => {
    if (!window.confirm('Are you sure you want to delete this style/POV setting?')) return;
    try {
      const { error } = await supabase
        .from('style_pov_settings')
        .delete()
        .eq('id', settingId);
      if (error) throw error;
      fetchStyleSettings();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFormSubmit = async () => {
    try {
      if (!formData.title.trim() || !formData.sampleText.trim()) {
        setError('Title and Sample Text are required.');
        return;
      }
      const payload = {
        user_id: user.id,
        book_id: bookId,
        title: formData.title.trim(),
        sample_text: formData.sampleText,
        references: formData.references
      };
      let response;
      if (currentSetting) {
        response = await supabase
          .from('style_pov_settings')
          .update(payload)
          .eq('id', currentSetting.id)
          .select()
          .single();
      } else {
        response = await supabase
          .from('style_pov_settings')
          .insert([payload])
          .select()
          .single();
      }
      if (response.error) throw response.error;
      setDialogOpen(false);
      fetchStyleSettings();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Style / POV Settings
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAdd}
          sx={{ mb: 2 }}
        >
          + Add Style / POV
        </Button>
        {loading ? (
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Sample Text</TableCell>
                  <TableCell>References</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {styleSettings.map((setting) => (
                  <TableRow key={setting.id}>
                    <TableCell>{setting.title}</TableCell>
                    <TableCell>{setting.sample_text.slice(0, 100)}…</TableCell>
                    <TableCell>{setting.references}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" onClick={() => handleOpenEdit(setting)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleDelete(setting.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{currentSetting ? 'Edit Style/POV Setting' : 'Add Style/POV Setting'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleFormChange}
              required
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Sample Text / Instructions"
              name="sampleText"
              value={formData.sampleText}
              onChange={handleFormChange}
              required
            />
            <TextField
              fullWidth
              label="References (authors, books, etc.)"
              name="references"
              value={formData.references}
              onChange={handleFormChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleFormSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StylePOVSettingsTable;