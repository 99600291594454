import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  CircularProgress,
  Stack,
  Typography,
  Tooltip
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import ImageUploader from '../common/ImageUploader';
import AIImageGeneratorModal from '../tools/AIImageGeneratorModal';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';
import LocationImagePickerDialog from './LocationImagePickerDialog';
import LocationSettingsDialog from './LocationSettingsDialog';

const LocationSettingsTable = ({ bookId }) => {
  const { user } = useAuth();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editLocation, setEditLocation] = useState(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false); // Add this line
  
  // Form state for Add/Edit
  const [formData, setFormData] = useState({
    name: '',
    geographicLocation: '',
    additionalDetails: '',
    imagePath: ''
  });

  const fetchLocations = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('settings')
        .select('*')
        .eq('user_id', user.id)
        .eq('book_id', bookId);
      if (error) throw error;
      setLocations(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bookId && user) {
      fetchLocations();
    }
  }, [bookId, user]);

  const handleOpenAdd = () => {
    setFormData({ name: '', geographicLocation: '', additionalDetails: '', imagePath: '' });
    setEditLocation(null);
    setAddDialogOpen(true);
  };

  const handleOpenEdit = (location) => {
    setEditLocation(location);
    setFormData({
      name: location.name || '',
      geographicLocation: location.geographic_location || '',
      additionalDetails: location.details
        ? Object.values(location.details).join('\n')
        : '',
      imagePath: location.image_path || ''
    });
    setAddDialogOpen(true);
  };

  const handleDelete = async (locationId) => {
    if (!window.confirm('Are you sure you want to delete this location?')) return;
    try {
      const { error } = await supabase
        .from('settings')
        .delete()
        .eq('setting_id', locationId);
      if (error) throw error;
      fetchLocations();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Utility: convert additionalDetails text area (one line per sentence) into JSON object with keys "1", "2" etc.
  const parseAdditionalDetails = (text) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const detailsObj = {};
    lines.forEach((line, index) => {
        detailsObj[String(index + 1)] = line.trim();
    });
    return detailsObj;
  };

  const handleFormSubmit = async () => {
    try {
      // Validate name and geographicLocation are provided
      if (!formData.name.trim() || !formData.geographicLocation.trim()) {
        setError('Name and Geographic Location are required.');
        return;
      }
      const detailsJSON = parseAdditionalDetails(formData.additionalDetails);
      const payload = {
        user_id: user.id,
        book_id: bookId,
        name: formData.name.trim(),
        geographic_location: formData.geographicLocation.trim(),
        details: detailsJSON,
        image_path: formData.imagePath || null,
        type: 'location'
      };
      let response;
      if (editLocation) {
        response = await supabase
          .from('settings')
          .update(payload)
          .eq('setting_id', editLocation.setting_id)
          .select()
          .single();
      } else {
        response = await supabase
          .from('settings')
          .insert([payload])
          .select()
          .single();
      }
      if (response.error) throw response.error;
      setAddDialogOpen(false);
      fetchLocations();
    } catch (err) {
      setError(err.message);
    }
  };

  // Image upload callback for the dialog – updates formData.imagePath
  const handleImageUpload = (filePath) => {
    setFormData(prev => ({ ...prev, imagePath: filePath }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Location Settings
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpenAdd}
        sx={{ mb: 2 }}
      >
        + Add Location
      </Button>
      
      {loading ? (
        <Box sx={{ textAlign: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Geographic Location</TableCell>
                <TableCell>Additional Details</TableCell>
                <TableCell>Image</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((loc) => (
                <TableRow key={loc.setting_id}>
                  <TableCell>{loc.name}</TableCell>
                  <TableCell>{loc.geographic_location}</TableCell>
                  <TableCell>
                    {loc.details ? Object.values(loc.details).join('; ') : '-'}
                  </TableCell>
                  <TableCell>
                    {loc.image_path ? (
                      <img
                        src={supabase.storage.getPublicUrl('images', loc.image_path).data.publicUrl}
                        alt="location"
                        style={{ width: 60, height: 40, objectFit: 'cover' }}
                      />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => handleOpenEdit(loc)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleDelete(loc.setting_id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{editLocation ? 'Edit Location' : 'Add Location'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Location Name"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              required
            />
            <TextField
              fullWidth
              label="Geographic Location"
              name="geographicLocation"
              value={formData.geographicLocation}
              onChange={handleFormChange}
              required
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Additional Details"
              name="additionalDetails"
              value={formData.additionalDetails}
              onChange={handleFormChange}
              placeholder="Enter one detail per line (e.g. 'beach in warm climate' on first line)"
            />
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Location Image
              </Typography>
              <ImageUploader
                onUpload={handleImageUpload}
                initialImage={formData.imagePath}
                bucket="images"
                path={`${user.id}/${bookId}/location`}
              />
              <AIImageGeneratorModal
                open={false} // You could add a button to launch AI generation
                onClose={() => {}}
                onAssign={handleImageUpload}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
                Location Image (optional)
            </Typography>
            {formData.imagePath && (
                <Box sx={{ mb: 1, textAlign: 'center' }}>
                <img
                    src={supabase.storage.getPublicUrl('images', formData.imagePath).data.publicUrl}
                    alt="Location"
                    style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                />
                </Box>
            )}
            <Button variant="outlined" onClick={() => setImageDialogOpen(true)}>
                {formData.imagePath ? 'Change Image' : 'Select Image'}
            </Button>
            </Box>
            <LocationImagePickerDialog
            open={imageDialogOpen}
            onClose={() => setImageDialogOpen(false)}
            onSelect={(filePath) => setFormData(prev => ({ ...prev, imagePath: filePath }))} 
            initialImage={formData.imagePath}
              bookId={bookId}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleFormSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LocationSettingsTable;