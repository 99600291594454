import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Alert,
  useTheme
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import supabase from '../../api/supabase';
import getSignedUrl from '../../utils/getSignedUrl';

const ImageUploader = ({
  onUpload,
  initialImage,
  bucket = 'images', // now using "images" bucket
  path = '',
  maxSize = 5000000
}) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);

  // Use our getSignedUrl utility to retrieve a preview URL from initialImage  
  useEffect(() => {
    let isMounted = true;
    const fetchUrl = async () => {
      if (initialImage && !initialImage.startsWith('http')) {
        const url = await getSignedUrl(bucket, initialImage, 60 * 60 * 24);
        if (isMounted) {
          setPreview(url);
        }
      } else if (initialImage) {
        setPreview(initialImage);
      } else {
        setPreview(null);
      }
    };
    fetchUrl();
    return () => { isMounted = false; };
  }, [initialImage, bucket]);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }
    if (file.size > maxSize) {
      setError(`File size must be less than ${maxSize / 1000000} MB`);
      return;
    }
    try {
      setUploading(true);
      setError(null);
      // Generate unique filename
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = path ? `${path}/${fileName}` : fileName;
      
      // Upload file to the same "images" bucket
      const { error: uploadError } = await supabase.storage
        .from(bucket)
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true,
          contentType: file.type
        });
      if (uploadError) throw uploadError;
      
      // Retrieve signed URL using our utility
      const signedUrl = await getSignedUrl(bucket, filePath, 60 * 60 * 24);
      if (!signedUrl) {
        throw new Error('Failed to create signed URL');
      }
      setPreview(signedUrl);
      if (onUpload) {
        onUpload(filePath);
      }
    } catch (err) {
      console.error('Upload error:', err);
      setError(err.message || 'Error uploading image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = () => {
    setPreview(null);
    if (onUpload) {
      onUpload(null);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Box
        sx={{
          border: `2px dashed ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: 3,
          textAlign: 'center',
          position: 'relative'
        }}
        onClick={() => (!preview && !uploading) && fileInputRef.current?.click()}
      >
        {preview ? (
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={preview}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '200px',
                objectFit: 'contain'
              }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white'
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <CloudUploadIcon sx={{ fontSize: 48, color: theme.palette.text.secondary }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Drop an image here or click to upload
            </Typography>
            <Typography variant="body2" color="textSecondary">
              JPG, PNG, GIF. Max {maxSize / 1000000}MB
            </Typography>
          </>
        )}
        {uploading && (
          <Box
            sx={{
              position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              backgroundColor: 'rgba(255,255,255,0.8)'
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileSelect}
      />
      {preview && (
        <Button fullWidth variant="outlined" sx={{ mt: 1 }} onClick={handleDelete} disabled={uploading}>
          Remove Image
        </Button>
      )}
    </Box>
  );
};

export default ImageUploader