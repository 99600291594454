import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import CoverArtPanel from './bookDetails/CoverArtPanel';
import BookDetailsPanel from './bookDetails/BookDetailsPanel';
import { useBook } from '../../hooks/useBook';

const DetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3)
}));

// Add this styled component
const EqualHeightGrid = styled(Grid)({
  '& > .MuiGrid-item': {
    display: 'flex',
    '& > *': { // This targets the Card components inside the grid items
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  }
});

function BookDetailsLayout({ bookId }) {
  const { book, loading, error, updateBook } = useBook(bookId);

  return (
    <DetailsContainer>
      <EqualHeightGrid container spacing={3}> {/* Changed from Grid to EqualHeightGrid */}
        <Grid item xs={12} md={4}>
          <CoverArtPanel 
            bookId={bookId}
            coverImage={book?.cover_image}
            onUpdate={updateBook}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <BookDetailsPanel 
            bookId={bookId}
            book={book}
            loading={loading}
            error={error}
            onUpdate={updateBook}
          />
        </Grid>
      </EqualHeightGrid>
    </DetailsContainer>
  );
}

export default BookDetailsLayout;