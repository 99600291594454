import { useAuth } from './useAuth';
import supabase from '../api/supabase';
import { useEffect, useState, useCallback } from 'react';

export function useBooks() {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const fetchBooks = useCallback(async () => {
    try {
      setLoading(true);

      // Fetch books
      const { data: booksData, error: booksError } = await supabase
        .from('books')
        .select('*')
        .eq('user_id', user.id);

      if (booksError) throw booksError;

      // Fetch characters for each book
      const booksWithCharacters = await Promise.all(
        booksData.map(async (book) => {
          const { data: charactersData, error: charactersError } = await supabase
            .from('characters')
            .select('*')
            .eq('book_id', book.book_id);

          if (charactersError) throw charactersError;

          return {
            ...book,
            characters: charactersData || []
          };
        })
      );

      setBooks(booksWithCharacters);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching books:', err);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchBooks();
    }
  }, [fetchBooks, user]);

  const addBook = useCallback(async (bookData) => {
    try {
      // Insert the book
      const { data: newBook, error: bookError } = await supabase
        .from('books')
        .insert([{ ...bookData, user_id: user.id }])
        .select()
        .single();

      if (bookError) throw bookError;

      // Fetch characters for the new book
      const { data: characters, error: charactersError } = await supabase
        .from('characters')
        .select('*')
        .eq('book_id', newBook.book_id);

      if (charactersError) throw charactersError;

      const bookWithCharacters = {
        ...newBook,
        characters: characters || []
      };

      setBooks(prev => [bookWithCharacters, ...prev]);
      return bookWithCharacters;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }, [user]);

  // Function to update the list of books
  const updateBooks = useCallback((updatedBook) => {
    setBooks(prevBooks =>
      prevBooks.map(book => 
        book.book_id === updatedBook.book_id ? { ...book, ...updatedBook } : book
      )
    );
  }, []);

  return { books, loading, error, addBook, fetchBooks, updateBooks };
}