import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import supabase from '../api/supabase';

export const useBook = (bookId) => {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const fetchBook = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('books')
        .select(`
          *,
          characters (*),
          chapters (*),
          plots (*),
          settings (*)
        `)
        .eq('book_id', bookId)
        .single();

      if (error) throw error;
      setBook(data);
      return data;
    } catch (err) {
      setError(err.message);
      console.error('Error fetching book:', err);
    } finally {
      setLoading(false);
    }
  };

  const updateBook = async (updates) => {
    try {
      const { data, error } = await supabase
        .from('books')
        .update({ ...updates, updated_at: new Date() })
        .eq('book_id', bookId)
        .select()
        .single();

      if (error) throw error;
      setBook(prev => ({ ...prev, ...data }));
      return data;
    } catch (err) {
      console.error('Error updating book:', err);
      throw err;
    }
  };

  const createCharacter = async (characterData) => {
    try {
      const { data, error } = await supabase
        .from('characters')
        .insert([{
          ...characterData,
          book_id: bookId,
          created_at: new Date(),
          updated_at: new Date()
        }])
        .select()
        .single();

      if (error) throw error;

      // Update local state
      setBook(prev => ({
        ...prev,
        characters: [...(prev.characters || []), data]
      }));

      return data;
    } catch (err) {
      console.error('Error creating character:', err);
      throw err;
    }
  };

  const updateCharacter = async (characterId, updates) => {
    try {
      const { data, error } = await supabase
        .from('characters')
        .update({
          ...updates,
          updated_at: new Date()
        })
        .eq('character_id', characterId)
        .eq('book_id', bookId) // Extra safety check
        .select()
        .single();

      if (error) throw error;

      // Update local state
      setBook(prev => ({
        ...prev,
        characters: prev.characters.map(char =>
          char.character_id === characterId ? data : char
        )
      }));

      return data;
    } catch (err) {
      console.error('Error updating character:', err);
      throw err;
    }
  };

  const deleteCharacter = async (characterId) => {
    try {
      const { error } = await supabase
        .from('characters')
        .delete()
        .eq('character_id', characterId)
        .eq('book_id', bookId); // Extra safety check

      if (error) throw error;

      // Update local state
      setBook(prev => ({
        ...prev,
        characters: prev.characters.filter(
          char => char.character_id !== characterId
        )
      }));
    } catch (err) {
      console.error('Error deleting character:', err);
      throw err;
    }
  };

  const updateChapter = async (chapterId, updates) => {
    try {
      const { data, error } = await supabase
        .from('chapters')
        .update({ ...updates, updated_at: new Date() })
        .eq('chapter_id', chapterId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (err) {
      console.error('Error updating chapter:', err);
      throw err;
    }
  };

  useEffect(() => {
    if (bookId) {
      fetchBook();
    }
  }, [bookId]);

  return {
    book,
    loading,
    error,
    updateBook,
    fetchBook,
    updateChapter,
    createCharacter,
    updateCharacter,
    deleteCharacter
  };
};