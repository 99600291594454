import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Stack,
  MenuItem,
  Card,
  CardMedia,
  CardActions,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Save,
  Image,
  Edit,
  DeleteOutline
} from '@mui/icons-material';
import supabase from '../../api/supabase';
import { useAuth } from '../../hooks/useAuth';
import AssignImageDialog from './AssignImageDialog';
import EditImageDialog from './EditImageDialog';

const AVAILABLE_MODELS = [
  { value: 'dall-e-2', label: 'DALL-E 2', available: true },
  { value: 'dall-e-3', label: 'DALL-E 3', available: false },
  { value: 'stable-diffusion', label: 'Stable Diffusion', available: false },
  { value: 'midjourney', label: 'Midjourney', available: false }
];

function ImageGenerator() {
  const { user } = useAuth();
  const [selectedModel, setSelectedModel] = useState('dall-e-2');
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // For "assign" and "edit" modals
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleGenerate = async () => {
    if (!prompt.trim() || loading) return;
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);

      // Example: call an Edge Function named 'generate-images'
      // (Adjust if your actual Edge Function is named differently)
      const { data, error: fnError } = await supabase.functions.invoke('generate-images', {
        body: {
          prompt,
          type: 'basic',
          subscriptionTier: 'free'  // or 'standard', 'premium', etc.
        }
      });
      if (fnError) throw fnError;
      if (!data?.images) {
        throw new Error('No images returned from the Edge Function.');
      }
      setGeneratedImages(data.images);
      setSuccess('Images generated successfully!');
    } catch (err) {
      console.error('Generate error:', err);
      setError(err.message || 'Failed to generate images');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveToGallery = async (img) => {
    try {
      const res = await fetch(img.url);
      if (!res.ok) {
        throw new Error('Failed to fetch generated image as blob');
      }
      const blob = await res.blob();

      // Unique path
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.png`;
      const filePath = `${user.id}/gallery/${fileName}`;

      // Upload
      const { error: uploadError } = await supabase.storage
        .from('images')
        .upload(filePath, blob, {
          contentType: 'image/png'
        });
      if (uploadError) throw uploadError;

      // Insert record into 'gallery'
      const { error: dbError } = await supabase
        .from('gallery')
        .insert([{
          user_id: user.id,
          image_path: filePath,
          prompt,
          type: 'default'  // Ensure this value satisfies the check constraint
        }]);
      if (dbError) throw dbError;

      setSuccess('Image saved to gallery!');
    } catch (err) {
      console.error('Save to gallery error:', err);
      setError(err.message);
    }
  };

  const handleAssignClick = (img) => {
    setSelectedImage(img);
    setAssignDialogOpen(true);
  };

  const handleEditClick = (img) => {
    setSelectedImage(img);
    setEditDialogOpen(true);
  };

  const handleRemoveImage = (index) => {
    setGeneratedImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Alerts */}
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      {/* Generation Controls */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Model
            </Typography>
            <TextField
              select
              fullWidth
              label="Select Model"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
              sx={{ mb: 2 }}
            >
              {AVAILABLE_MODELS.map((m) => (
                <MenuItem key={m.value} value={m.value} disabled={!m.available}>
                  {m.label} {!m.available && '(Coming soon)'}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <TextField
                label="Prompt"
                multiline
                rows={3}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Describe the image you want..."
              />
              <TextField
                label="Negative Prompt (optional)"
                multiline
                rows={2}
                value={negativePrompt}
                onChange={(e) => setNegativePrompt(e.target.value)}
                placeholder="What should be avoided in the image?"
              />
              <Button
                variant="contained"
                onClick={handleGenerate}
                disabled={!prompt.trim() || loading}
              >
                {loading ? 'Generating...' : 'Generate Images'}
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      {/* Display generated images */}
      <Box>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={2}>
            {generatedImages.map((img, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    image={img.url}
                    alt="Generated result"
                    sx={{ aspectRatio: '1', objectFit: 'cover' }}
                  />
                  <CardActions>
                    <Button
                      size="small"
                      startIcon={<Save />}
                      onClick={() => handleSaveToGallery(img)}
                    >
                      Gallery
                    </Button>
                    <Button
                      size="small"
                      startIcon={<Image />}
                      onClick={() => handleAssignClick(img)}
                    >
                      Assign
                    </Button>
                    <Button
                      size="small"
                      startIcon={<Edit />}
                      onClick={() => handleEditClick(img)}
                    >
                      Edit
                    </Button>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Assign Dialog */}
      <AssignImageDialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
        imageUrl={selectedImage?.url}
        onSave={() => {
          // If you need to re-fetch or do anything after assignment, do it here
        }}
      />

      {/* Edit Dialog (stub) */}
      <EditImageDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        image={selectedImage}
        onSave={() => {
          // If you want to re-generate or do something after edit
        }}
      />
    </Box>
  );
}

export default ImageGenerator