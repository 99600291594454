import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Alert,
  Box,
  Stack,
  Divider,
  Button,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ImageUploader from '../../common/ImageUploader';
import { useAuth } from '../../../hooks/useAuth';
import { useBook } from '../../../hooks/useBook';
import CollectionsIcon from '@mui/icons-material/Collections';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import GallerySelectModal from '../../tools/GallerySelectModal';
import AIImageGeneratorModal from '../../tools/AIImageGeneratorModal';

function CoverArtPanel({ bookId }) {
  const theme = useTheme();
  const { user } = useAuth();
  const { book, updateBook } = useBook(bookId);
  const [coverImagePath, setCoverImagePath] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  // New state to control modals
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [aiModalOpen, setAiModalOpen] = useState(false);

  // When the book loads from the DB, use its cover_image to set local state
  useEffect(() => {
    if (book && book.cover_image) {
      setCoverImagePath(book.cover_image);
    }
  }, [book]);

  // When a user uploads (or removes) an image, call updateBook.
  const handleUpload = async (filePath) => {
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      // IMPORTANT: Pass an object so that updateBook receives valid column keys.
      await updateBook({ cover_image: filePath || null });
      setCoverImagePath(filePath);
      setSuccess(filePath ? 'Cover art uploaded successfully!' : 'Cover art removed.');
    } catch (err) {
      console.error('Error updating book cover:', err);
      setError(err.message || 'Failed to update cover image.');
    } finally {
      setLoading(false);
    }
  };

  // Simple functions to show the modals:
  const handleGalleryOpen = () => setGalleryOpen(true);
  const handleGenerateAI = () => setAiModalOpen(true);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Cover Art
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}

        <Box sx={{ mb: 3 }}>
          <ImageUploader
            onUpload={handleUpload}
            initialImage={coverImagePath}
            bucket="images"
            path={`${user?.id}/${bookId || 'new'}`} // Folder structure inside the "images" bucket
          />
        </Box>

        <Divider sx={{ my: 2 }}>
          <Typography variant="body2" color="text.secondary">
            or
          </Typography>
        </Divider>

        <Stack spacing={2}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<CollectionsIcon />}
            onClick={handleGalleryOpen}
          >
            Choose from Gallery
          </Button>

          <Button
            fullWidth
            variant="outlined"
            startIcon={<AutoFixHighIcon />}
            onClick={handleGenerateAI}
          >
            Generate with AI
          </Button>
        </Stack>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </CardContent>

      {/* Render modals */}
      <GallerySelectModal
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        onAssign={(filePath) => { handleUpload(filePath); }}
      />

      <AIImageGeneratorModal
        open={aiModalOpen}
        onClose={() => setAiModalOpen(false)}
        onAssign={(filePath) => { handleUpload(filePath); }}
      />
    </Card>
  );
}

export default CoverArtPanel