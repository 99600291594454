import React from 'react';
import { Card, Box, Typography, Button, IconButton, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import supabase from '../../../api/supabase';

const StyledCard = styled(Card)({
  width: 300,
  display: 'flex',
  flexDirection: 'column'
});

async function getSignedUrl(imagePath) {
  if (!imagePath) return null;
  try {
    const { data, error } = await supabase.storage
      .from('images')
      .createSignedUrl(imagePath, 60 * 60 * 24); // 24 hours
    if (error) {
      console.error('Error creating signed URL:', error);
      return null;
    }
    return data.signedUrl;
  } catch (err) {
    console.error('Unexpected error in getSignedUrl:', err);
    return null;
  }
}

function CharacterCard({ character, onEdit, onChat, onImageUpload }) {
  const [imgUrl, setImgUrl] = React.useState(null);

  React.useEffect(() => {
    let isMounted = true;
    if (character?.image_path) {
      getSignedUrl(character.image_path).then((url) => {
        if (isMounted) {
          setImgUrl(url);
        }
      });
    } else {
      setImgUrl(null);
    }
    return () => {
      isMounted = false;
    };
  }, [character?.image_path]);

  return (
    <StyledCard sx={{ p: 2, mb: 2, position: 'relative' }}>
      <Box sx={{ textAlign: 'center' }}>
        <img
          src={imgUrl || 'https://via.placeholder.com/120x120?text=No+Avatar'}
          alt={character.name || 'Character'}
          style={{
            width: 120,
            height: 120,
            borderRadius: '50%',
            objectFit: 'cover',
            marginBottom: '12px'
          }}
        />
        {/* Icon button to trigger the ChangeAvatarModal */}
        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'white'
          }}
          onClick={() => onImageUpload(character)}
        >
          <AddPhotoAlternateIcon />
        </IconButton>
        <Typography variant="h6" color="primary">
          {character.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {character.role || 'No role'}
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" color="primary">
          About
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {character.background_story?.slice(0, 120) || 'No background yet...'}
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" color="primary">
          Traits
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {Array.isArray(character?.personality_traits)
            ? character.personality_traits.map((trait, idx) => (
                <Box
                  key={idx}
                  sx={{
                    backgroundColor: '#fff5f8',
                    color: '#a83663',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 2,
                    fontSize: '0.8rem'
                  }}
                >
                  {trait}
                </Box>
              ))
            : null}
        </Box>
      </Box>

      <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => onEdit(character)}
          sx={{ flex: 1 }}
        >
          Edit / View
        </Button>
        <Button
          variant="outlined"
          startIcon={<ChatIcon />}
          onClick={() => onChat(character)}
          sx={{ flex: 1 }}
        >
          Chat
        </Button>
      </Box>
    </StyledCard>
  );
}

export default CharacterCard