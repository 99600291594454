// src/components/tools/plotPoints/PlotPointDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  Stack,
  Chip,
  Alert,
  Divider,
  Autocomplete,
  useTheme,
  styled
} from '@mui/material';
import {
  Person as PersonIcon,
  Star as StarIcon,
  Info as InfoIcon
} from '@mui/icons-material';

const TypeChip = styled(Chip)(({ selected, color, theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  height: 'auto',
  padding: '8px',
  borderRadius: '8px',
  backgroundColor: selected ? `${color}15` : 'transparent',
  border: `1px solid ${selected ? color : theme.palette.divider}`,
  '& .MuiChip-label': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px',
  },
  '&:hover': {
    backgroundColor: `${color}10`,
  }
}));

const PLOT_POINT_TYPES = {
  CHARACTER_DEVELOPMENT: {
    value: 'character_development',
    label: 'Character Development',
    icon: PersonIcon,
    color: 'primary',
    description: 'Track how events affect character growth and change'
  },
  KEY_MOMENT: {
    value: 'key_moment',
    label: 'Key Moment',
    icon: StarIcon,
    color: 'secondary',
    description: 'Major plot developments and turning points'
  },
  BACKGROUND_CONTEXT: {
    value: 'background_context',
    label: 'Background Context',
    icon: InfoIcon,
    color: 'info',
    description: 'Supplementary information that enriches the story'
  }
};

const PlotPointDialog = ({ 
  open, 
  onClose, 
  plotPoint = null, 
  onSave,
  bookId,
  characters = []
}) => {
  const theme = useTheme();  // Add this line
  const [formData, setFormData] = useState({
    type: '',
    title: '',
    description: '',
    impact: '',
    related_characters: [],
    character_effects: {},
    context: {}
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (plotPoint) {
      setFormData({
        type: plotPoint.type || '',
        title: plotPoint.title || '',
        description: plotPoint.description || '',
        impact: plotPoint.impact || '',
        related_characters: plotPoint.related_characters || [],
        character_effects: plotPoint.character_effects || {},
        context: plotPoint.context || {}
      });
    } else {
      setFormData({
        type: '',
        title: '',
        description: '',
        impact: '',
        related_characters: [],
        character_effects: {},
        context: {}
      });
    }
  }, [plotPoint]);

  const handleSubmit = () => {
    if (!formData.title.trim() || !formData.type) {
      setError('Title and type are required');
      return;
    }
    onSave(formData);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {plotPoint ? 'Edit Plot Point' : 'Add Plot Point'}
      </DialogTitle>

      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Stack spacing={3} sx={{ mt: 2 }}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Type of Plot Point
            </Typography>
            <Stack spacing={1}>
              {Object.entries(PLOT_POINT_TYPES).map(([key, type]) => {
                const Icon = type.icon;
                return (
                  <TypeChip
                    key={key}
                    icon={<Icon />}
                    label={
                      <Box>
                        <Typography variant="subtitle2">
                          {type.label}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {type.description}
                        </Typography>
                      </Box>
                    }
                    onClick={() => setFormData(prev => ({ ...prev, type: type.value }))}
                    selected={formData.type === type.value}
                    color={theme.palette[type.color].main}
                  />
                );
              })}
            </Stack>
          </Box>

          <TextField
            fullWidth
            label="Title"
            value={formData.title}
            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
            required
          />

          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            value={formData.description}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
          />

          <TextField
            fullWidth
            multiline
            rows={2}
            label="Impact on Story"
            value={formData.impact}
            onChange={(e) => setFormData(prev => ({ ...prev, impact: e.target.value }))}
            helperText="How does this affect the story or characters?"
          />

          {formData.type === 'character_development' && (
            <>
              <Divider>Character Details</Divider>
              <Autocomplete
                multiple
                options={characters}
                getOptionLabel={(option) => option.name}
                value={characters.filter(char => 
                  formData.related_characters.includes(char.character_id)
                )}
                onChange={(_, newValue) => setFormData(prev => ({
                  ...prev,
                  related_characters: newValue.map(char => char.character_id)
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Related Characters"
                    placeholder="Select characters"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
              />
            </>
          )}

          {formData.type === 'background_context' && (
            <>
              <Divider>Additional Context</Divider>
              <Stack spacing={2}>
                <TextField
                  label="Historical Context"
                  multiline
                  rows={2}
                  value={formData.context.historical || ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    context: { ...prev.context, historical: e.target.value }
                  }))}
                />
                <TextField
                  label="Cultural Significance"
                  multiline
                  rows={2}
                  value={formData.context.cultural || ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    context: { ...prev.context, cultural: e.target.value }
                  }))}
                />
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
        >
          {plotPoint ? 'Save Changes' : 'Add Plot Point'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlotPointDialog;